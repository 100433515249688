import Cookies from 'js-cookie';
import React,{Component,Fragment} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Divider,
    Dropdown,
    Header,
    Icon,
    Input,
    Form,
    Menu,
    Modal,
    Segment
} from 'semantic-ui-react';

import {
    Photo
} from '../components';
import {
    apiFetch,
    humanStatus,
    message
} from '../utils';

class SerialViewer extends Component{
    constructor(props){
        super(props);

        this.state={
            mode:this.props.mode,
            id:this.props.match.params.serial,
            data:{
                source:'',
                email:'',
                public:'',
                private:'',
                status:''
            },
            modal:false,
            errors:[]
        };

        this.handleBanner=this.handleBanner.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleClick=this.handleClick.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    componentDidMount(){
        const {
            id
        }=this.state;

        if(id){
            apiFetch({
                url:'/api/serials/'+id,
                method:'GET',
                success:(data)=>{
                    this.setState({
                        data:data
                    });
                },
                error:(error)=>{
                    message(false,error.body.message);
                }
            });
        }
    }

    handleBanner(files){
        const {
            id
        }=this.state;

        let formData=new FormData(),
            file=files[0].src.file,
            status;

        formData.append(
            'banner',
            file,
            file.name
        );

        fetch('/api/serials/'+id+'/banner',{
            method:'POST',
            credentials:'same-origin',
            headers:{
                'Accept':'application/json',
                'CSRF-Token':Cookies.get('firmanza.admin.csrf')
            },
            body:formData
        })
        .then((response)=>{
            status=response.status;

            return response.json();
        })
        .then((body)=>{
            if(status===200||status===201){
                this.componentDidMount();

                message(true,body.message);
            }else{
                message(false,body.message);
            }
        })
        .catch((e)=>{
            console.log(e);

            message(false,'No puede conectarse con el API');
        });
    }

    handleClose(){
        this.setState({
            modal:false
        });
    }

    handleChange(event,_data){
        const {
            data
        }=this.state;

        this.setState({
            data:{
                ...data,
                [_data.name]:_data.value
            }
        });
    }

    handleClick(event,_data){
        const {
            id
        }=this.state;

        event.stopPropagation();

        switch(_data['data-name']){
            case 'activate':
                apiFetch({
                    url:'/api/serials/'+id+'/activate',
                    method:'POST',
                    success:(__data)=>{
                        this.props.history.push('/serials/'+id+'/view');
                        this.componentDidMount();

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'deactivate':
                apiFetch({
                    url:'/api/serials/'+id+'/deactivate',
                    method:'POST',
                    success:(__data)=>{
                        this.props.history.push('/serials/'+id+'/view');
                        this.componentDidMount();

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'archive':
                this.setState({
                    modal:true
                });

                break;
            case 'modal-archive':
                apiFetch({
                    url:'/api/serials/'+id+'/archive',
                    method:'POST',
                    success:(__data)=>{
                        this.setState({
                            modal:false
                        });

                        this.props.history.push('/serials');

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            default:
                break;
        }
    }

    handleSubmit(event){
        const {
            mode,
            id,
            data
        }=this.state;

        event.preventDefault();

        if(mode==='edit'){
            apiFetch({
                url:'/api/serials/'+id,
                method:'PUT',
                data:{
                    source:data.source,
                    email:data.email
                },
                success:(__data)=>{
                    this.props.history.push('/serials');

                    message(__data.ok,__data.message);
                },
                error:(error)=>{
                    message(false,error.body.message);
                }
            });
        }
    }

    render(){
        const {
            mode,
            id,
            data,
            modal,
            errors
        }=this.state;

        if(
            !this.props.auth.logged||
            this.props.auth.role!=='admin'
        ){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container fluid>
                <Header as='h1'>Seriales</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>Inicio</a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <a href='/serials' className='section'>
                        Seriales
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    {mode==='edit'&&
                        <Fragment>
                            <a
                                href={'/serials/'+id+'/view'}
                                className='section'>
                                Ver registro
                            </a>
                            <i aria-hidden='true'
                                className='right angle icon divider'></i>
                        </Fragment>
                    }
                    {{
                        'view':<div className='active section'>
                            Ver registro</div>,
                        'edit':<div className='active section'>
                            Editar registro</div>
                    }[mode]}
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/serials'
                        name='Seriales activos'
                    />
                    <Menu.Item
                        as='a'
                        href='/serials/archive'
                        name='Seriales revocados'
                    />
                    {{
                        'view':
                            <Menu.Item
                                as='a'
                                href={'/serials/'+id+'/view'}
                                name='Ver registro'
                                active
                            />,
                        'edit':
                            <Menu.Item
                                as='a'
                                href={'/serials/'+id+'/edit'}
                                name='Editar registro'
                                active
                            />
                    }[mode]}
                </Menu>

                {mode==='view'&&
                    <Fragment>
                        <Header as='h4' dividing>
                            Banner (400px,130px)
                        </Header>
                        <Segment
                            basic
                            placeholder
                        >
                            <Photo
                                image={data.banner}
                                errors={errors}
                                onSuccess={this.handleBanner}
                                setState={this.setState}
                                width={400}
                                height={130}
                            />
                        </Segment>
                    </Fragment>
                }

                <Form onSubmit={this.handleSubmit}>
                    <Header as='h4' dividing>
                        Datos personales
                    </Header>

                    <Form.Group widths='equal'>
                        <Form.Field required={mode!=='view'}>
                            <label htmlFor='source'>
                                Origen
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='source'
                                    size='small'
                                >
                                    &nbsp;{data.source}
                                </Segment>:
                                <Input
                                    name='source'
                                    type='text'
                                    icon='user'
                                    iconPosition='left'
                                    value={data.source||''}
                                    onChange={this.handleChange}
                                    required
                                />
                            }
                        </Form.Field>
                        <Form.Field required={mode!=='view'}>
                            <label htmlFor='email'>
                                Correo electrónico
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='email'
                                    size='small'
                                >
                                    &nbsp;{data.email}
                                </Segment>:
                                <Input
                                    name='email'
                                    type='text'
                                    icon='mail'
                                    iconPosition='left'
                                    value={data.email||''}
                                    onChange={this.handleChange}
                                    required
                                />
                            }
                        </Form.Field>
                    </Form.Group>

                    <Header as='h4' dividing>
                        Datos de acceso
                    </Header>

                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label htmlFor='public'>Serial</label>
                            <Segment
                                name='public'
                                size='small'
                            >
                                &nbsp;{data.public}
                            </Segment>
                        </Form.Field>
                        <Form.Field>
                            <label htmlFor='private'>Registrado</label>
                            <Segment
                                name='private'
                                size='small'
                            >
                                {data.private?
                                    <Icon
                                        name='square'
                                        size='large'
                                    />:
                                    <Icon
                                        name='square outline'
                                        size='large'
                                    />
                                }
                            </Segment>
                        </Form.Field>
                    </Form.Group>

                    {mode==='view'&&
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <Form.Field>
                                    <label htmlFor='status'>
                                        Estado del registro
                                    </label>
                                    <Segment
                                        name='status'
                                        size='small'
                                    >
                                        {humanStatus(data.status||'')}
                                    </Segment>
                                </Form.Field>
                            </Form.Field>
                            <Form.Field>
                            </Form.Field>
                        </Form.Group>
                    }

                    <Divider />
                    {{
                        'view':
                            <Fragment>
                                {data.status==='active'?
                                    <Button
                                        icon
                                        color='red'
                                        labelPosition='left'
                                        data-name='deactivate'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='thumbs down' />
                                        Desactivar
                                    </Button>:
                                    <Button
                                        icon
                                        color='green'
                                        labelPosition='left'
                                        data-name='activate'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='thumbs up' />
                                        Activar
                                    </Button>
                                }

                                <Dropdown
                                    text='Opciones'
                                    icon='tasks'
                                    className='icon'
                                    labeled
                                    button
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            icon='save'
                                            content='Modificar'
                                            href={'/serials/'+id+'/edit'}
                                        />

                                        <Dropdown.Item
                                            icon='box'
                                            content='Archivar'
                                            data-name='archive'
                                            onClick={this.handleClick}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Fragment>,
                        'edit':
                            <Button
                                icon
                                color='blue'
                                labelPosition='left'
                                type='submit'
                            >
                                <Icon name='save' />
                                Guardar
                            </Button>
                    }[mode]}

                    <Modal
                        basic
                        size='small'
                        open={modal}
                        onClose={this.handleClose}
                    >
                        <Header
                            icon='archive'
                            content='Confirmación de archivo'
                        />

                        <Modal.Content>
                            <p>
                                ¿Está seguro que desea archivar el registro?
                            </p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                basic
                                inverted
                                color='red'
                                onClick={this.handleClose}
                            >
                                <Icon name='remove' /> No
                            </Button>
                            <Button
                                inverted
                                color='green'
                                data-name='modal-archive'
                                onClick={this.handleClick}
                            >
                                <Icon name='checkmark' /> Sí
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Form>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth
    };
};

export default connect(mapStateToProps)(SerialViewer);

