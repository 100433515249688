import Cookies from 'js-cookie';
import {
    AUTH_IN,
    AUTH_OUT
} from '../constants';

const session=Cookies.get('firmanza.admin.session')||'false',
    role=Cookies.get('firmanza.admin.role')||'',
    initialState={
        logged:session==='true'?true:false,
        role:role
    };

const auth=(state=initialState,action)=>{
    switch(action.type){
        case AUTH_IN:
            return {
                logged:true,
                role:action.role
            };
        case AUTH_OUT:
            return {
                logged:false,
                role:''
            };
        default:
            return state;
    }
};

export default auth;

