import moment from 'moment';
import React,{Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Grid,
    Header,
    Icon,
    Image,
    Input,
    Menu,
    Message,
    Modal,
    Table
} from 'semantic-ui-react';

import {
    Pagination
} from '../components';
import {
    PAGINATION_LIMIT
} from '../config';
import {
    apiFetch,
    message
} from '../utils';

class SerialArchive extends Component{
    constructor(props){
        super(props);

        this.state={
            error:null,
            isLoaded:false,
            total:0,
            items:[],
            search:'',
            page:1,
            modal:false,
            archive:null,
            action:null
        };

        this.handleSearch=this.handleSearch.bind(this);
        this.handlePagination=this.handlePagination.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleClick=this.handleClick.bind(this);
    }

    loadData(search,page){
        apiFetch({
            url:'/api/serials/archive?'+[
                'search='+search,
                'offset='+((page-1)*PAGINATION_LIMIT),
                'limit='+PAGINATION_LIMIT
            ].join('&'),
            method:'GET',
            success:(data)=>{
                this.setState({
                    isLoaded:true,
                    total:data.total,
                    items:data.list,
                    search:search,
                    page:page
                });
            },
            error:(error)=>{
                this.setState({
                    isLoaded:true,
                    error:error
                });
            }
        });
    }

    componentDidMount(){
        this.loadData('',1);
    }

    handleSearch(event,data){
        this.loadData(data.value,1);
    }

    handlePagination(event,data){
        const {
            search
        }=this.state;

        this.loadData(search,data.activePage);
    }

    handleClose(){
        this.setState({
            modal:false
        });
    }

    handleClick(event,data){
        const {
            items,
            archive
        }=this.state;

        event.stopPropagation();

        let index=+data['data-index'];

        switch(data['data-name']){
            case 'unarchive':
                this.setState({
                    modal:true,
                    archive:items[index]._id,
                    action:'unarchive'
                });

                break;
            case 'modal-unarchive':
                apiFetch({
                    url:'/api/serials/'+archive+'/unarchive',
                    method:'POST',
                    success:(data)=>{
                        this.setState({
                            modal:false,
                            archive:null,
                            action:null
                        });

                        message(data.ok,data.message);

                        this.componentDidMount();
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'delete':
                this.setState({
                    modal:true,
                    archive:items[index]._id,
                    action:'delete'
                });

                break;
            case 'modal-delete':
                apiFetch({
                    url:'/api/serials/'+archive,
                    method:'DELETE',
                    success:(data)=>{
                        this.setState({
                            modal:false,
                            archive:null,
                            action:null
                        });

                        message(data.ok,data.message);

                        this.componentDidMount();
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            default:
                break;
        }
    }

    render(){
        const {
            action,
            error,
            isLoaded,
            items,
            modal,
            page,
            total
        }=this.state;

        if(
            !this.props.auth.logged||
            this.props.auth.role!=='admin'
        ){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container fluid>
                <Header as='h1'>Seriales</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>Inicio</a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <a href='/serials' className='section'>
                        Seriales
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <div className='active section'>
                        Seriales revocados
                    </div>
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/serials'
                        name='Seriales activos'
                    />
                    <Menu.Item
                        as='a'
                        href='/serials/archive'
                        name='Seriales revocados'
                        active
                    />
                </Menu>

                <Grid>
                    <Grid.Column width={12}>
                        &nbsp;
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Input
                            className='search-input'
                            size='small'
                            icon={{
                                name:'search',
                                circular:true,
                                link:true
                            }}
                            placeholder='Buscar...'
                            onChange={this.handleSearch}
                        />
                    </Grid.Column>
                </Grid>
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Detalles
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width={2}>
                                Serial
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width={1}>
                                &nbsp;
                            </Table.HeaderCell>
                            <Table.HeaderCell width={2}>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {error&&
                            <Table.Row>
                                <Table.Cell colSpan='4'>
                                    <Message negative>
                                        <Message.Header>
                                            No pudieron cargarse los datos
                                        </Message.Header>
                                        <p>{error.body.message}</p>
                                    </Message>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {!isLoaded&&
                            <Table.Row>
                                <Table.Cell colSpan='4'>
                                    <Message icon info>
                                        <Icon name='circle notched' loading />
                                        <Message.Content>
                                            <Message.Header>
                                                Espera un momento
                                            </Message.Header>
                                            <p>
                                                Cargando la información del
                                                servidor ...
                                            </p>
                                        </Message.Content>
                                    </Message>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {items.map((item,i)=>(
                            <Table.Row
                                key={item._id}
                                className={item.status==='inactive'?'error':''}
                            >
                                <Table.Cell>
                                    <Header as='h4' image>
                                        <Image
                                            size='medium'
                                            src={item.banner} />
                                        <Header.Content>
                                            {moment(item.ts)
                                            .format('YYYY-MM-DD HH:mm:ss')}
                                            <Header.Subheader>
                                                {item.source}
                                                &lt;{item.email}&gt;
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {item.public}
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {item.private?
                                        <Icon
                                            name='square'
                                            size='large'
                                        />:
                                        <Icon
                                            name='square outline'
                                            size='large'
                                        />
                                    }
                                </Table.Cell>
                                <Table.Cell textAlign='right'>
                                    <Button.Group basic size='small'>
                                        <Button
                                            icon='undo'
                                            title='Deshacer archivado'
                                            data-name='unarchive'
                                            data-index={i}
                                            onClick={this.handleClick}
                                        />
                                        <Button
                                            icon='trash'
                                            title='Eliminar registro'
                                            data-name='delete'
                                            data-index={i}
                                            onClick={this.handleClick}
                                        />
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>

                    <Pagination
                        total={total}
                        limit={PAGINATION_LIMIT}
                        page={page}
                        colspan={4}
                        handlePagination={this.handlePagination}
                    />
                </Table>

                <Modal
                    basic
                    size='small'
                    open={modal}
                    onClose={this.handleClose}
                >
                    <Header
                        icon={action==='unarchive'?'undo':'trash'}
                        content={
                            action==='unarchive'?
                                'Confirmación de reactivación':
                                'Confirmación de eliminación'
                        }
                    />

                    <Modal.Content>
                        {action==='unarchive'?
                            <p>
                                ¿Está seguro que desea reactivar el registro?
                            </p>:
                            <p>
                                ¿Está seguro que desea eliminar
                                permanentemente el registro?
                            </p>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            basic
                            inverted
                            color='red'
                            onClick={this.handleClose}
                        >
                            <Icon name='remove' /> No
                        </Button>
                        {action==='unarchive'?
                            <Button
                                inverted
                                color='green'
                                data-name='modal-unarchive'
                                onClick={this.handleClick}
                            >
                                <Icon name='checkmark' /> Sí
                            </Button>:
                            <Button
                                inverted
                                color='green'
                                data-name='modal-delete'
                                onClick={this.handleClick}
                            >
                                <Icon name='checkmark' /> Sí
                            </Button>
                        }
                    </Modal.Actions>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth
    };
};

export default connect(mapStateToProps)(SerialArchive);

