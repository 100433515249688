import es from 'date-fns/locale/es';
import Cookies from 'js-cookie';
import React,{Component,Fragment} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import DatePicker,{registerLocale} from 'react-datepicker';
import {
    Button,
    Checkbox,
    Container,
    Divider,
    Dropdown,
    Header,
    Icon,
    Image,
    Input,
    Flag,
    Form,
    Label,
    Menu,
    Modal,
    Segment,
    Table
} from 'semantic-ui-react';

import Countries from '../translate/countries.es';
import {
    Photo
} from '../components';
import {
    COUNTRIES_WITHOUT_FLAG
} from '../config';
import {
    apiFetch,
    humanStatus,
    message
} from '../utils';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('es',es);

class CustomerViewer extends Component{
    constructor(props){
        super(props);

        this.state={
            mode:this.props.mode,
            id:this.props.match.params.customer,
            data:{
                name:'',
                surname:'',
                id:'',
                birthdate:null,
                year:0,
                month:0,
                date:0,
                country:'',
                phone:'',
                email:'',
                password1:'',
                password2:'',
                status:'',
                permissions:{},
                quota:128
            },
            enterprises:[],
            permissions:{},
            modal:false,
            confirm_type:'',
            invalid_email:false,
            invalid_email_text:'',
            invalid_password:false,
            errors:[]
        };

        this.handlePhoto=this.handlePhoto.bind(this);
        this.handleDatePicker=this.handleDatePicker.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleClick=this.handleClick.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    componentDidMount(){
        const {
            id,
            permissions
        }=this.state;

        apiFetch({
            url:'/api/customers/permissions',
            method:'GET',
            success:(data)=>{
                this.setState({
                    permissions:data
                });

                if(!id){
                    this.setState({
                        data:{
                            permissions:Object.keys(permissions)
                            .map((i)=>{
                                return {
                                    key:i,
                                    value:permissions[i].value
                                };
                            })
                            .reduce((sum,i)=>{
                                sum[i.key]=i.value;

                                return sum;
                            },{})
                        }
                    });
                }
            },
            error:(error)=>{
                message(false,error.body.message);
            }
        });

        apiFetch({
            url:'/api/enterprises?limit=-1',
            method:'GET',
            success:(data)=>{
                this.setState({
                    enterprises:data.list
                });
            },
            error:(error)=>{
                message(false,error.body.message);
            }
        });

        if(id){
            apiFetch({
                url:'/api/customers/'+id,
                method:'GET',
                success:(data)=>{
                    this.setState({
                        data:{
                            ...data,
                            birthdate:data.year?
                                new Date(
                                    data.year,data.month,data.date
                                ):
                                null
                        }
                    });
                },
                error:(error)=>{
                    message(false,error.body.message);
                }
            });
        }
    }

    getCountries(){
        return Countries
        .map((country)=>{
            return {
                key:country.code,
                value:country.code,
                flag:COUNTRIES_WITHOUT_FLAG.includes(country.code)?
                    null:country.code,
                text:country.label
            };
        });
    }

    getCountry(code){
        let item=Countries.find((i)=>{
            return i.code===code;
        });

        if(item){
            return item.label;
        }else{
            return '---';
        }
    }

    getPermission(permission){
        const {
            permissions
        }=this.state.data;

        if(permissions){
            if(permission in permissions){
                return permissions[permission];
            }else{
                return false;
            }
        }else{
            return false;
        }
    }

    handlePhoto(files){
        const {
            id
        }=this.state;

        let formData=new FormData(),
            file=files[0].src.file,
            status;

        formData.append(
            'photo',
            file,
            file.name
        );

        fetch('/api/customers/'+id+'/photo',{
            method:'POST',
            credentials:'same-origin',
            headers:{
                'Accept':'application/json',
                'CSRF-Token':Cookies.get('firmanza.admin.csrf')
            },
            body:formData
        })
        .then((response)=>{
            status=response.status;

            return response.json();
        })
        .then((body)=>{
            if(status===200||status===201){
                this.componentDidMount();

                message(true,body.message);
            }else{
                message(false,body.message);
            }
        })
        .catch((e)=>{
            console.log(e);

            message(false,'No puede conectarse con el API');
        });
    }

    handleDatePicker(date){
        const {
            data
        }=this.state;

        this.setState({
            data:{
                ...data,
                birthdate:date,
                year:date?date.getFullYear():0,
                month:date?date.getMonth():0,
                date:date?date.getDate():0
            }
        });
    }

    handleClose(){
        this.setState({
            modal:false,
            confirm_type:''
        });
    }

    handleChange(event,_data){
        const {
            data
        }=this.state;

        if([
            'name',
            'surname',
            'id',
            'year',
            'month',
            'date',
            'country',
            'phone',
            'email',
            'password1',
            'password2',
            'quota'
        ].includes(_data.name)){
            this.setState({
                data:{
                    ...data,
                    [_data.name]:_data.value
                }
            });

            if(_data.name==='password1'){
                this.setState({
                    invalid_password:(
                        data.password2!==_data.value
                    )
                });
            }

            if(_data.name==='password2'){
                this.setState({
                    invalid_password:(
                        data.password1!==_data.value
                    )
                });
            }
        }else{
            this.setState({
                data:{
                    ...data,
                    permissions:{
                        ...data.permissions,
                        [_data.name]:_data.checked
                    }
                }
            });
        }
    }

    handleClick(event,_data){
        const {
            id
        }=this.state;

        event.stopPropagation();

        switch(_data['data-name']){
            case 'activate':
                apiFetch({
                    url:'/api/customers/'+id+'/activate',
                    method:'POST',
                    success:(__data)=>{
                        this.props.history.push('/customers/'+id+'/view');
                        this.componentDidMount();

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'deactivate':
                apiFetch({
                    url:'/api/customers/'+id+'/deactivate',
                    method:'POST',
                    success:(__data)=>{
                        this.props.history.push('/customers/'+id+'/view');
                        this.componentDidMount();

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'archive':
                this.setState({
                    modal:true,
                    confirm_type:'archive'
                });

                break;
            case 'modal-archive':
                apiFetch({
                    url:'/api/customers/'+id+'/archive',
                    method:'POST',
                    success:(__data)=>{
                        this.setState({
                            modal:false
                        });

                        this.props.history.push('/customers');

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'confirm':
                apiFetch({
                    url:'/api/customers/'+id+'/confirm',
                    method:'POST',
                    success:(__data)=>{
                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'leave':
                apiFetch({
                    url:'/api/customers/'+id+'/leave',
                    method:'POST',
                    success:(__data)=>{
                        this.props.history.push('/customers/'+id+'/view');
                        this.componentDidMount();

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'assign':
                apiFetch({
                    url:'/api/customers/'+id+'/assign',
                    method:'POST',
                    success:(__data)=>{
                        this.props.history.push('/customers/'+id+'/view');
                        this.componentDidMount();

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'unassign':
                apiFetch({
                    url:'/api/customers/'+id+'/unassign',
                    method:'POST',
                    success:(__data)=>{
                        this.props.history.push('/customers/'+id+'/view');
                        this.componentDidMount();

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'join':
                this.setState({
                    modal:true,
                    confirm_type:'join'
                });

                break;
            case 'modal-join':{
                let enterprise_id=_data['data-value'];

                apiFetch({
                    url:'/api/enterprises/'+enterprise_id+
                        '/customers/'+id+'/join',
                    method:'POST',
                    success:(__data)=>{
                        this.setState({
                            modal:false
                        });

                        this.props.history.push('/customers/'+id+'/view');
                        this.componentDidMount();

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            }
            default:
                break;
        }
    }

    handleSubmit(event){
        const {
            mode,
            id,
            data
        }=this.state;

        event.preventDefault();

        if(mode==='add'){
            apiFetch({
                url:'/api/customers',
                method:'POST',
                data:{
                    surname:data.surname,
                    name:data.name,
                    id:data.id,
                    year:data.year,
                    month:data.month,
                    date:data.date,
                    country:data.country,
                    phone:data.phone,
                    email:data.email,
                    password:data.password1,
                    permissions:data.permissions,
                    quota:data.quota
                },
                success:(_data)=>{
                    this.props.history.push('/customers');

                    message(_data.ok,_data.message);
                },
                error:(error)=>{
                    if(error.status===400){
                        this.setState({
                            invalid_email:true,
                            invalid_email_text:error.body.message
                        });
                    }else{
                        message(false,error.body.message);
                    }
                }
            });
        }

        if(mode==='edit'){
            apiFetch({
                url:'/api/customers/'+id,
                method:'PUT',
                data:{
                    surname:data.surname,
                    name:data.name,
                    id:data.id,
                    year:data.year,
                    month:data.month,
                    date:data.date,
                    country:data.country,
                    phone:data.phone,
                    email:data.email,
                    permissions:data.permissions,
                    quota:data.quota
                },
                success:(_data)=>{
                    this.props.history.push('/customers/'+id+'/view');
                    this.componentDidMount();

                    message(_data.ok,_data.message);
                },
                error:(error)=>{
                    if(error.status===400){
                        this.setState({
                            invalid_email:true,
                            invalid_email_text:error.body.message
                        });
                    }else{
                        message(false,error.body.message);
                    }
                }
            });
        }
    }

    render(){
        const {
            mode,
            id,
            data,
            enterprises,
            permissions,
            modal,
            confirm_type,
            invalid_email,
            invalid_email_text,
            invalid_password,
            errors
        }=this.state;

        if(
            !this.props.auth.logged||
            this.props.auth.role!=='employee'
        ){
            return (
                <Redirect to='/' />
            );
        }

        const options=(
                <Dropdown.Menu>
                    <Dropdown.Item
                        icon='save'
                        content='Modificar'
                        href={'/customers/'+id+'/edit'}
                    />

                    <Dropdown.Item
                        icon='box'
                        content='Archivar'
                        data-name='archive'
                        onClick={this.handleClick}
                    />

                    {data.status!=='acquired'?
                        <Dropdown.Item
                            icon='mail'
                            content={'Correo de confirmación'}
                            data-name='confirm'
                            onClick={this.handleClick}
                        />:''
                    }

                    <Dropdown.Divider />

                    {data.enterprise?
                        <Dropdown.Item
                            icon='sign-out'
                            content={'Remover de la empresa'}
                            data-name='leave'
                            onClick={this.handleClick}
                        />:
                        (data.status==='acquired'?
                            <Dropdown.Item
                                icon='sign-in'
                                content={'Administrar la cuenta'}
                                data-name='assign'
                                onClick={this.handleClick}
                            />:
                            <Fragment>
                                <Dropdown.Item
                                    icon='sign-out'
                                    content={'Liberar la cuenta'}
                                    data-name='unassign'
                                    onClick={this.handleClick}
                                />
                                <Dropdown.Item
                                    icon='sign-in'
                                    content={'Agregar a una empresa'}
                                    data-name='join'
                                    onClick={this.handleClick}
                                />
                            </Fragment>
                        )
                    }
                </Dropdown.Menu>
            ),
            add_account=(
                <Table
                    basic='very'
                    celled
                    inverted
                    selectable
                >
                    <Table.Body>
                        {enterprises
                        .filter(enterprise=>enterprise.status==='active')
                        .map(enterprise=>(
                            <Table.Row
                                key={enterprise._id}
                            >
                                <Table.Cell>
                                    <Header
                                        as='h4'
                                        image
                                        inverted={true}
                                    >
                                        <Image
                                            circular
                                            size='medium'
                                            src={enterprise.photo}
                                        />
                                        <Header.Content>
                                            {enterprise.name}
                                            <Header.Subheader>
                                                <Flag
                                                    name={enterprise.country}
                                                />
                                                {this.getCountry(
                                                    enterprise.country
                                                )}
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='center'
                                >
                                    <Icon name='user' />
                                    {enterprise.customers}
                                </Table.Cell>
                                <Table.Cell
                                    textAlign='right'
                                >
                                    <Button
                                        icon='plus'
                                        size='mini'
                                        data-name='modal-join'
                                        data-value={enterprise._id}
                                        onClick={this.handleClick}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            );

        return (
            <Container fluid>
                <Header as='h1'>Clientes</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>Inicio</a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <a href='/customers' className='section'>
                        Clientes
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    {mode==='edit'&&
                        <Fragment>
                            <a
                                href={'/customers/'+id+'/view'}
                                className='section'>
                                Ver registro
                            </a>
                            <i aria-hidden='true'
                                className='right angle icon divider'></i>
                        </Fragment>
                    }
                    {{
                        'add':<div className='active section'>
                            Nuevo registro</div>,
                        'view':<div className='active section'>
                            Ver registro</div>,
                        'edit':<div className='active section'>
                            Editar registro</div>
                    }[mode]}
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/customers'
                        name='Clientes activos'
                    />
                    <Menu.Item
                        as='a'
                        href='/customers/archive'
                        name='Clientes archivados'
                    />
                    {{
                        'add':
                            <Menu.Item
                                as='a'
                                href='/customers/add'
                                name='Nuevo registro'
                                active
                            />,
                        'view':
                            <Menu.Item
                                as='a'
                                href={'/customers/'+id+'/view'}
                                name='Ver registro'
                                active
                            />,
                        'edit':
                            <Menu.Item
                                as='a'
                                href={'/customers/'+id+'/edit'}
                                name='Editar registro'
                                active
                            />
                    }[mode]}
                </Menu>

                {mode==='view'&&
                    <Fragment>
                        <Header as='h4' dividing>
                            Fotografía
                        </Header>
                        <Segment
                            basic
                            placeholder
                        >
                            <Photo
                                image={data.photo}
                                errors={errors}
                                onSuccess={this.handlePhoto}
                                setState={this.setState}
                            />
                        </Segment>
                    </Fragment>
                }

                <Form onSubmit={this.handleSubmit}>
                    <Header as='h4' dividing>
                        Datos personales
                    </Header>

                    <Form.Group widths='equal'>
                        <Form.Field required={mode!=='view'}>
                            <label htmlFor='name'>
                                Nombres
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='name'
                                    size='small'
                                >
                                    {data.name}
                                </Segment>:
                                <Input
                                    name='name'
                                    type='text'
                                    icon='user'
                                    iconPosition='left'
                                    value={data.name||''}
                                    onChange={this.handleChange}
                                    required
                                />
                            }
                        </Form.Field>
                        <Form.Field required={mode!=='view'}>
                            <label htmlFor='surname'>
                                Apellidos
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='surname'
                                    size='small'
                                >
                                    {data.surname}
                                </Segment>:
                                <Input
                                    name='surname'
                                    type='text'
                                    icon='user'
                                    iconPosition='left'
                                    value={data.surname||''}
                                    onChange={this.handleChange}
                                    required
                                />
                            }
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field required={mode!=='view'}>
                            <label htmlFor='id'>
                                Cédula de identidad personal o pasaporte
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='id'
                                    size='small'
                                >
                                    {data.id}
                                </Segment>:
                                <Input
                                    name='id'
                                    type='text'
                                    icon='id card'
                                    iconPosition='left'
                                    value={data.id||''}
                                    onChange={this.handleChange}
                                    required
                                />
                            }
                        </Form.Field>
                        <Form.Field required={mode!=='view'}>
                            <label htmlFor='birthdate'>
                                Fecha de nacimiento
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='birthdate'
                                    size='small'
                                >
                                    {data.year?
                                        <Fragment>
                                            {data.year}-
                                            {data.month+1}-
                                            {data.date}
                                        </Fragment>:
                                        <Fragment>
                                            ---
                                        </Fragment>
                                    }
                                </Segment>:
                                <DatePicker
                                    name='birthdate'
                                    selected={data.birthdate}
                                    dateFormat='yyyy-MM-dd'
                                    locale='es'
                                    dropdownMode='select'
                                    onChange={this.handleDatePicker}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                />
                            }
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label htmlFor='country'>
                                País
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='country'
                                    size='small'
                                >
                                    {this.getCountry(data.country)}
                                </Segment>:
                                <Dropdown
                                    name='country'
                                    placeholder='Seleccione un país'
                                    options={this.getCountries()}
                                    value={data.country}
                                    onChange={this.handleChange}
                                    fluid
                                    search
                                    selection
                                />
                            }
                        </Form.Field>
                        <Form.Field>
                            <label htmlFor='phone'>
                                Teléfono
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='phone'
                                    size='small'
                                >
                                    {data.phone}
                                </Segment>:
                                <Input
                                    name='phone'
                                    type='text'
                                    icon='phone'
                                    iconPosition='left'
                                    value={data.phone||''}
                                    onChange={this.handleChange}
                                    required
                                />
                            }
                        </Form.Field>
                    </Form.Group>

                    <Header as='h4' dividing>
                        Datos de acceso
                    </Header>

                    <Form.Group widths='equal'>
                        <Form.Field required={mode!=='view'}>
                            <label htmlFor='email'>
                                Correo electrónico
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='email'
                                    size='small'
                                >
                                    {data.email}
                                </Segment>:
                                <Fragment>
                                    <Input
                                        name='email'
                                        type='email'
                                        icon='mail'
                                        iconPosition='left'
                                        value={data.email||''}
                                        onChange={this.handleChange}
                                        required
                                    />
                                    {invalid_email&&
                                        <Label basic color='red' pointing>
                                            {invalid_email_text}
                                        </Label>
                                    }
                                </Fragment>
                            }
                        </Form.Field>
                        <Form.Field>
                            {mode==='view'&&data.enterprise&&
                                <Fragment>
                                    <label htmlFor='enterprise'>
                                        Empresa
                                    </label>
                                    <Segment
                                        name='enterprise'
                                        size='small'
                                    >
                                        {(enterprises.find((i)=>{
                                            return i._id===data.enterprise;
                                        })||{}).name}
                                    </Segment>
                                </Fragment>
                            }
                        </Form.Field>
                    </Form.Group>

                    {mode==='add'&&
                        <Form.Group widths='equal'>
                            <Form.Field required>
                                <label htmlFor='password1'>
                                    Contraseña
                                </label>
                                <Input
                                    name='password1'
                                    type='password'
                                    icon='key'
                                    iconPosition='left'
                                    value={data.password1||''}
                                    onChange={this.handleChange}
                                    required
                                />
                                {invalid_password&&
                                    <Label basic color='red' pointing>
                                        Contraseñas diferentes
                                    </Label>
                                }
                            </Form.Field>
                            <Form.Field required>
                                <label htmlFor='password2'>
                                    Repetir contraseña
                                </label>
                                <Input
                                    name='password2'
                                    type='password'
                                    icon='key'
                                    iconPosition='left'
                                    value={data.password2||''}
                                    onChange={this.handleChange}
                                    required
                                />
                                {invalid_password&&
                                    <Label basic color='red' pointing>
                                        Contraseñas diferentes
                                    </Label>
                                }
                            </Form.Field>
                        </Form.Group>
                    }

                    {mode==='view'&&
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <Form.Field>
                                    <label htmlFor='status'>
                                        Estado de la cuenta
                                    </label>
                                    <Segment
                                        name='status'
                                        size='small'
                                    >
                                        {humanStatus(data.status||'')}
                                    </Segment>
                                </Form.Field>
                            </Form.Field>
                            <Form.Field>
                            </Form.Field>
                        </Form.Group>
                    }

                    <Header as='h4' dividing>
                        Recursos disponibles
                    </Header>

                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label htmlFor='quota'>
                                Espacio en disco (MiB)
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='quota'
                                    size='small'
                                >
                                    {data.quota}
                                </Segment>:
                                <Input
                                    name='quota'
                                    type='number'
                                    icon='disk'
                                    iconPosition='left'
                                    value={data.quota||128}
                                    onChange={this.handleChange}
                                    required
                                />
                            }
                        </Form.Field>
                        <Form.Field>
                        </Form.Field>
                    </Form.Group>

                    <Header as='h4' dividing>
                        Funcionalidad disponible
                    </Header>

                    {Object.keys(permissions).map((permission)=>{
                        return (
                            <Fragment key={permission}>
                                <Form.Field>
                                    <Checkbox
                                        toggle
                                        id={permission}
                                        name={permission}
                                        label={permissions[permission]
                                        .description}
                                        onChange={this.handleChange}
                                        checked={this.getPermission(permission)}
                                        readOnly={mode==='view'}
                                    />
                                </Form.Field>
                                <Form.Field>
                                </Form.Field>
                            </Fragment>
                        );
                    })}

                    <Divider />
                    {{
                        'add':
                            <Button
                                icon
                                color='blue'
                                labelPosition='left'
                                type='submit'
                            >
                                <Icon name='save' />
                                Guardar
                            </Button>,
                        'view':
                            <Fragment>
                                {data.status!=='acquired'&&
                                    (data.status==='active'?
                                        <Button
                                            icon
                                            color='red'
                                            labelPosition='left'
                                            data-name='deactivate'
                                            onClick={this.handleClick}
                                        >
                                            <Icon name='thumbs down' />
                                            Desactivar
                                        </Button>:
                                        <Button
                                            icon
                                            color='green'
                                            labelPosition='left'
                                            data-name='activate'
                                            onClick={this.handleClick}
                                        >
                                            <Icon name='thumbs up' />
                                            Activar
                                        </Button>
                                    )
                                }

                                <Dropdown
                                    text='Opciones'
                                    icon='tasks'
                                    className='icon'
                                    labeled
                                    button
                                >
                                    {options}
                                </Dropdown>
                            </Fragment>,
                        'edit':
                            <Button
                                icon
                                color='blue'
                                labelPosition='left'
                                type='submit'
                            >
                                <Icon name='save' />
                                Guardar
                            </Button>
                    }[mode]}

                    <Modal
                        basic
                        size='small'
                        open={modal}
                        onClose={this.handleClose}
                    >
                        {confirm_type==='archive'&&
                            <Header icon='archive'
                                content='Confirmación de archivo'
                            />
                        }
                        {confirm_type==='join'&&
                            <Header icon='sign-in'
                                content='Seleccione la empresa'
                            />
                        }

                        <Modal.Content>
                            {confirm_type==='archive'&&
                                <p>
                                    ¿Está seguro que desea archivar el
                                    registro?
                                </p>
                            }
                            {confirm_type==='join'&&
                                <Fragment>
                                    <p>
                                        Escoja la empresa a la que quiera
                                        agregar la cuenta:
                                    </p>
                                    {add_account}
                                </Fragment>
                            }
                        </Modal.Content>
                        <Modal.Actions>
                            {confirm_type==='archive'&&
                                <Fragment>
                                    <Button
                                        basic
                                        inverted
                                        color='red'
                                        onClick={this.handleClose}
                                    >
                                        <Icon name='remove' /> No
                                    </Button>
                                    <Button
                                        inverted
                                        color='green'
                                        data-name='modal-archive'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='checkmark' /> Sí
                                    </Button>
                                </Fragment>
                            }
                        </Modal.Actions>
                    </Modal>
                </Form>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth
    };
};

export default connect(mapStateToProps)(CustomerViewer);

