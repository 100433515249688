import React,{Component} from 'react';
import {loadReCaptcha,ReCaptcha} from 'react-recaptcha-v3';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Header,
    Input,
    Form,
    Label
} from 'semantic-ui-react';

import {
    CAPTCHA_PUBLIC
} from '../config';
import {
    apiFetch,
    message
} from '../utils';

class Reset extends Component{
    constructor(props){
        super(props);

        this.key=this.props.match.params.key;
        this.state={
            csrf:'',
            email:'',
            password1:'',
            password2:'',
            captcha:'',
            invalid_email:false,
            invalid_email_text:'',
            invalid_password:false
        };

        this.handleCaptcha=this.handleCaptcha.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    componentDidMount(){
        loadReCaptcha(CAPTCHA_PUBLIC);

        apiFetch({
            url:'/api/csrf',
            method:'GET',
            success:(data)=>{
                this.setState({
                    csrf:data.csrf
                });
            },
            error:(error)=>{}
        });
    }

    handleCaptcha(token){
        this.setState({
            captcha:token
        });
    }

    handleChange(event,data){
        const {
            password1,
            password2
        }=this.state;

        this.setState({
            [data.name]:data.value
        });

        if(data.name==='password1'){
            this.setState({
                invalid_password:(password2!==data.value)
            });
        }

        if(data.name==='password2'){
            this.setState({
                invalid_password:(password1!==data.value)
            });
        }
    }

    handleSubmit(event){
        const {
            csrf,
            email,
            password1,
            captcha
        }=this.state;

        event.preventDefault();

        apiFetch({
            url:'/api/reset/'+this.key,
            method:'POST',
            data:{
                csrf:csrf,
                email:email,
                password:password1,
                captcha:captcha
            },
            success:(data)=>{
                this.props.history.push('/signin');

                message(data.ok,data.message);
            },
            error:(error)=>{
                if(error.status===400){
                    this.setState({
                        invalid_email:true,
                        invalid_email_text:error.body.message
                    });
                }else{
                    message(false,error.body.message);
                }
            }
        });
    }

    render(){
        const {
            email,
            invalid_email,
            invalid_email_text,
            invalid_password,
            password1,
            password2
        }=this.state;

        if(this.props.auth.logged){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container id='central'>
                <Header as='h1'>Cambio de contraseña</Header>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Field>
                        <label htmlFor='email'>Correo electrónico</label>
                        <Input
                            name='email'
                            type='email'
                            value={email}
                            onChange={this.handleChange}
                            icon='mail'
                            iconPosition='left'
                            required
                        />
                        {invalid_email&&
                            <Label basic color='red' pointing>
                                {invalid_email_text}
                            </Label>
                        }
                    </Form.Field>
                    <Form.Field>
                        <label htmlFor='password1'>Contraseña</label>
                        <Input
                            name='password1'
                            type='password'
                            value={password1}
                            onChange={this.handleChange}
                            icon='key'
                            iconPosition='left'
                            required
                        />
                        {invalid_password&&
                            <Label basic color='red' pointing>
                                Contraseñas diferentes
                            </Label>
                        }
                    </Form.Field>
                    <Form.Field>
                        <label htmlFor='password2'>Repetir contraseña</label>
                        <Input
                            name='password2'
                            type='password'
                            value={password2}
                            onChange={this.handleChange}
                            icon='key'
                            iconPosition='left'
                            required
                        />
                        {invalid_password&&
                            <Label basic color='red' pointing>
                                Contraseñas diferentes
                            </Label>
                        }
                    </Form.Field>
                    <Button
                        color='black'
                        fluid
                    >
                        Ingresar
                    </Button>
                </Form>

                <ReCaptcha
                    sitekey={CAPTCHA_PUBLIC}
                    action='forgot'
                    verifyCallback={this.handleCaptcha}
                />
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth
    };
};

export default connect(mapStateToProps)(Reset);

