import Cookies from 'js-cookie';
import React,{Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Image,
    Input,
    Form
} from 'semantic-ui-react';

import {
    AUTH_IN
} from '../constants';
import {
    apiFetch,
    message
} from '../utils';
 
class Signin extends Component{
    constructor(props){
        super(props);

        this.state={
            email:'',
            password:''
        };

        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    handleChange(event,data){
        this.setState({
            [data.name]:data.value
        });
    }

    handleSubmit(event){
        const {
            email,
            password
        }=this.state;

        event.preventDefault();

        apiFetch({
            url:'/api/_session',
            method:'POST',
            data:{
                email:email,
                password:password
            },
            success:(data)=>{
                this.props.login(Cookies.get('firmanza.admin.role')||'');
                this.props.history.push('/');

                message(data.ok,data.message);
            },
            error:(error)=>{
                message(false,error.body.message);
            }
        });
    }

    render(){
        const {
            email,
            password
        }=this.state;

        if(this.props.auth.logged){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container id='central'>
                <Image src='/firmanza.svg'
                    size='medium'
                    centered
                />

                <Form onSubmit={this.handleSubmit}>
                    <Form.Field>
                        <label htmlFor='email'>Correo electrónico</label>
                        <Input
                            name='email'
                            type='email'
                            value={email}
                            onChange={this.handleChange}
                            icon='mail'
                            iconPosition='left'
                            required
                        />
                    </Form.Field>
                    <Form.Field>
                        <label htmlFor='password'>Contraseña</label>
                        <Input
                            name='password'
                            type='password'
                            value={password}
                            onChange={this.handleChange}
                            icon='key'
                            iconPosition='left'
                            required
                        />
                    </Form.Field>
                    <Form.Field>
                        <a href='/forgot'>Olvidé mi contraseña</a>
                    </Form.Field>
                    <Button
                        color='black'
                        fluid
                    >
                        Ingresar
                    </Button>
                </Form>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth
    };
};

const mapDispatchToProps=(dispatch)=>{
    return {
        login:(role)=>{
            dispatch({
                type:AUTH_IN,
                role:role
            });
        }
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(Signin);

