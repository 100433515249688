import React,{Fragment} from 'react';
import Files from 'react-butterfiles';
import {
    Image,
    Message
} from 'semantic-ui-react';

export default ({
    image,
    errors,
    onSuccess,
    setState,
    width=250,
    height=250
})=>(
    <Files
        multiple={false}
        maxSize='10mb'
        multipleMaxSize='10mb'
        accept={['image/png','image/jpg','image/jpeg']}
        onSuccess={onSuccess}
        onError={errors=>setState({errors})}
    >
        {({
            browseFiles,
            getDropZoneProps
        })=>(
            <Fragment>
                <div
                    onClick={browseFiles}
                    aria-hidden='true'
                    {...getDropZoneProps({
                        style:{
                            width:width,
                            minHeight:height,
                            border:'1px dashed black',
                            borderRadius:'3px',
                            margin:'0 auto',
                            padding:'4px'
                        }
                    })}
                >
                    <Image
                        fluid
                        rounded
                        src={image}
                    />
                </div>
                {errors.map(error=>(
                    <Message
                        compact
                        key={errors.file.name}
                        color='red'
                    >
                        {
                            {
                                'unsupportedFileType':
                                    'Formato de fichero no soportado '+
                                    '(Solo JPG o PNG)',
                                'maxSizeExceeded':
                                    'Archivo demasiado grande para '+
                                    'utilizarse (Máximo 2Mb)'
                            }[error.type]
                        }
                    </Message>
                ))}
            </Fragment>
        )}
    </Files>
);

