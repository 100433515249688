import Cookies from 'js-cookie';
import React,{Component,Fragment} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Divider,
    Dropdown,
    Header,
    Icon,
    Input,
    Form,
    Label,
    Menu,
    Modal,
    Segment
} from 'semantic-ui-react';

import {
    Photo
} from '../components';
import {
    apiFetch,
    humanStatus,
    message
} from '../utils';

class EmployeeViewer extends Component{
    constructor(props){
        super(props);

        this.state={
            mode:this.props.mode,
            id:this.props.match.params.employee,
            data:{
                name:'',
                surname:'',
                id:'',
                email:'',
                password1:'',
                password2:'',
                status:''
            },
            modal:false,
            invalid_email:false,
            invalid_email_text:'',
            invalid_password:false,
            errors:[]
        };

        this.handlePhoto=this.handlePhoto.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleClick=this.handleClick.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    componentDidMount(){
        const {
            id
        }=this.state;

        if(id){
            apiFetch({
                url:'/api/staff/'+id,
                method:'GET',
                success:(data)=>{
                    this.setState({
                        data:data
                    });
                },
                error:(error)=>{
                    message(false,error.body.message);
                }
            });
        }
    }

    handlePhoto(files){
        const {
            id
        }=this.state;

        let formData=new FormData(),
            file=files[0].src.file,
            status;

        formData.append(
            'photo',
            file,
            file.name
        );

        fetch('/api/staff/'+id+'/photo',{
            method:'POST',
            credentials:'same-origin',
            headers:{
                'Accept':'application/json',
                'CSRF-Token':Cookies.get('firmanza.admin.csrf')
            },
            body:formData
        })
        .then((response)=>{
            status=response.status;

            return response.json();
        })
        .then((body)=>{
            if(status===200||status===201){
                this.componentDidMount();

                message(true,body.message);
            }else{
                message(false,body.message);
            }
        })
        .catch((e)=>{
            console.log(e);

            message(false,'No puede conectarse con el API');
        });
    }

    handleClose(){
        this.setState({
            modal:false
        });
    }

    handleChange(event,_data){
        const {
            data
        }=this.state;

        this.setState({
            data:{
                ...data,
                [_data.name]:_data.value
            }
        });

        if(_data.name==='password1'){
            this.setState({
                invalid_password:(data.password2!==_data.value)
            });
        }

        if(_data.name==='password2'){
            this.setState({
                invalid_password:(data.password1!==_data.value)
            });
        }
    }

    handleClick(event,_data){
        const {
            id
        }=this.state;

        event.stopPropagation();

        switch(_data['data-name']){
            case 'activate':
                apiFetch({
                    url:'/api/staff/'+id+'/activate',
                    method:'POST',
                    success:(__data)=>{
                        this.props.history.push('/staff/'+id+'/view');
                        this.componentDidMount();

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'deactivate':
                apiFetch({
                    url:'/api/staff/'+id+'/deactivate',
                    method:'POST',
                    success:(__data)=>{
                        this.props.history.push('/staff/'+id+'/view');
                        this.componentDidMount();

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'archive':
                this.setState({
                    modal:true
                });

                break;
            case 'modal-archive':
                apiFetch({
                    url:'/api/staff/'+id+'/archive',
                    method:'POST',
                    success:(__data)=>{
                        this.setState({
                            modal:false
                        });

                        this.props.history.push('/staff');

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'confirm':
                apiFetch({
                    url:'/api/staff/'+id+'/confirm',
                    method:'POST',
                    success:(__data)=>{
                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            default:
                break;
        }
    }

    handleSubmit(event){
        const {
            mode,
            id,
            data
        }=this.state;

        event.preventDefault();

        if(mode==='add'){
            apiFetch({
                url:'/api/staff',
                method:'POST',
                data:{
                    surname:data.surname,
                    name:data.name,
                    id:data.id,
                    email:data.email,
                    password:data.password1
                },
                success:(_data)=>{
                    this.props.history.push('/staff');

                    message(_data.ok,_data.message);
                },
                error:(error)=>{
                    if(error.status===400){
                        this.setState({
                            invalid_email:true,
                            invalid_email_text:error.body.message
                        });
                    }else{
                        message(false,error.body.message);
                    }
                }
            });
        }

        if(mode==='edit'){
            apiFetch({
                url:'/api/staff/'+id,
                method:'PUT',
                data:{
                    surname:data.surname,
                    name:data.name,
                    id:data.id,
                    email:data.email
                },
                success:(_data)=>{
                    this.props.history.push('/staff/'+id+'/view');
                    this.componentDidMount();

                    message(_data.ok,_data.message);
                },
                error:(error)=>{
                    if(error.status===400){
                        this.setState({
                            invalid_email:true,
                            invalid_email_text:error.body.message
                        });
                    }else{
                        message(false,error.body.message);
                    }
                }
            });
        }
    }

    render(){
        const {
            mode,
            id,
            data,
            modal,
            invalid_email,
            invalid_email_text,
            invalid_password,
            errors
        }=this.state;

        if(
            !this.props.auth.logged||
            this.props.auth.role!=='admin'
        ){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container fluid>
                <Header as='h1'>Personal</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>Inicio</a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <a href='/staff' className='section'>Personal</a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    {mode==='edit'&&
                        <Fragment>
                            <a
                                href={'/staff/'+id+'/view'}
                                className='section'>
                                Ver registro
                            </a>
                            <i aria-hidden='true'
                                className='right angle icon divider'></i>
                        </Fragment>
                    }
                    {
                        {
                            'add':<div className='active section'>
                                Nuevo registro</div>,
                            'view':<div className='active section'>
                                Ver registro</div>,
                            'edit':<div className='active section'>
                                Editar registro</div>
                        }[mode]
                    }
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/staff'
                        name='Personal activo'
                    />
                    <Menu.Item
                        as='a'
                        href='/staff/archive'
                        name='Personal archivado'
                    />
                    {{
                        'add':
                            <Menu.Item
                                as='a'
                                href='/staff/add'
                                name='Nuevo registro'
                                active
                            />,
                        'view':
                            <Menu.Item
                                as='a'
                                href={'/staff/'+id+'/view'}
                                name='Ver registro'
                                active
                            />,
                        'edit':
                            <Menu.Item
                                as='a'
                                href={'/staff/'+id+'/edit'}
                                name='Editar registro'
                                active
                            />
                    }[mode]}
                </Menu>

                {mode==='view'&&
                    <Fragment>
                        <Header as='h4' dividing>
                            Fotografía
                        </Header>

                        <Segment
                            basic
                            placeholder
                        >
                            <Photo
                                image={data.photo}
                                errors={errors}
                                onSuccess={this.handlePhoto}
                                setState={this.setState}
                            />
                        </Segment>
                    </Fragment>
                }

                <Form onSubmit={this.handleSubmit}>
                    <Header as='h4' dividing>
                        Datos personales
                    </Header>

                    <Form.Group widths='equal'>
                        <Form.Field required={mode!=='view'}>
                            <label htmlFor='name'>
                                Nombres
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='name'
                                    size='small'
                                >
                                    {data.name}
                                </Segment>:
                                <Input
                                    name='name'
                                    type='text'
                                    icon='user'
                                    iconPosition='left'
                                    value={data.name||''}
                                    onChange={this.handleChange}
                                    required
                                />
                            }
                        </Form.Field>
                        <Form.Field required={mode!=='view'}>
                            <label htmlFor='surname'>
                                Apellidos
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='surname'
                                    size='small'
                                >
                                    {data.surname}
                                </Segment>:
                                <Input
                                    name='surname'
                                    type='text'
                                    icon='user'
                                    iconPosition='left'
                                    value={data.surname||''}
                                    onChange={this.handleChange}
                                    required
                                />
                            }
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field required={mode!=='view'}>
                            <label htmlFor='id'>
                                Cédula de identidad personal o pasaporte
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='id'
                                    size='small'
                                >
                                    {data.id}
                                </Segment>:
                                <Input
                                    name='id'
                                    type='text'
                                    icon='id card'
                                    iconPosition='left'
                                    value={data.id||''}
                                    onChange={this.handleChange}
                                    required
                                />
                            }
                        </Form.Field>
                        <Form.Field>
                        </Form.Field>
                    </Form.Group>

                    <Header as='h4' dividing>
                        Datos de acceso
                    </Header>

                    <Form.Group widths='equal'>
                        <Form.Field required={mode!=='view'}>
                            <label htmlFor='email'>
                                Correo electrónico
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='email'
                                    size='small'
                                >
                                    {data.email}
                                </Segment>:
                                <Fragment>
                                    <Input
                                        name='email'
                                        type='email'
                                        icon='mail'
                                        iconPosition='left'
                                        value={data.email||''}
                                        onChange={this.handleChange}
                                        required
                                    />
                                    {invalid_email&&
                                        <Label basic color='red' pointing>
                                            {invalid_email_text}
                                        </Label>
                                    }
                                </Fragment>
                            }
                        </Form.Field>
                        <Form.Field>
                        </Form.Field>
                    </Form.Group>

                    {mode==='add'&&
                        <Form.Group widths='equal'>
                            <Form.Field required>
                                <label htmlFor='password1'>
                                    Contraseña
                                </label>
                                <Input
                                    name='password1'
                                    type='password'
                                    icon='key'
                                    iconPosition='left'
                                    value={data.password1||''}
                                    onChange={this.handleChange}
                                    required
                                />
                                {invalid_password&&
                                    <Label basic color='red' pointing>
                                        Contraseñas diferentes
                                    </Label>
                                }
                            </Form.Field>
                            <Form.Field required>
                                <label htmlFor='password2'>
                                    Repetir contraseña
                                </label>
                                <Input
                                    name='password2'
                                    type='password'
                                    icon='key'
                                    iconPosition='left'
                                    value={data.password2||''}
                                    onChange={this.handleChange}
                                    required
                                />
                                {invalid_password&&
                                    <Label basic color='red' pointing>
                                        Contraseñas diferentes
                                    </Label>
                                }
                            </Form.Field>
                        </Form.Group>
                    }

                    {mode==='view'&&
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <Form.Field>
                                    <label htmlFor='status'>
                                        Estado de la cuenta
                                    </label>
                                    <Segment
                                        name='status'
                                        size='small'
                                    >
                                        {humanStatus(data.status||'')}
                                    </Segment>
                                </Form.Field>
                            </Form.Field>
                            <Form.Field>
                            </Form.Field>
                        </Form.Group>
                    }

                    <Divider />
                    {{
                        'add':
                            <Button
                                icon
                                color='blue'
                                labelPosition='left'
                                type='submit'
                            >
                                <Icon name='save' />
                                Guardar
                            </Button>,
                        'view':
                            <Fragment>
                                {data.status==='active'?
                                    <Button
                                        icon
                                        color='red'
                                        labelPosition='left'
                                        data-name='deactivate'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='thumbs down' />
                                        Desactivar
                                    </Button>:
                                    <Button
                                        icon
                                        color='green'
                                        labelPosition='left'
                                        data-name='activate'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='thumbs up' />
                                        Activar
                                    </Button>
                                }

                                <Dropdown
                                    text='Opciones'
                                    icon='tasks'
                                    className='icon'
                                    labeled
                                    button
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            icon='save'
                                            content='Modificar'
                                            href={'/staff/'+id+'/edit'}
                                        />
                                        <Dropdown.Item
                                            icon='box'
                                            content='Archivar'
                                            data-name='archive'
                                            onClick={this.handleClick}
                                        />
                                        <Dropdown.Item
                                            icon='mail'
                                            content='Correo de confirmación'
                                            data-name='confirm'
                                            onClick={this.handleClick}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Fragment>,
                        'edit':
                            <Button
                                icon
                                color='blue'
                                labelPosition='left'
                                type='submit'
                            >
                                <Icon name='save' />
                                Guardar
                            </Button>
                    }[mode]}

                    <Modal
                        basic
                        size='small'
                        open={modal}
                        onClose={this.handleClose}
                    >
                        <Header
                            icon='archive'
                            content='Confirmación de archivo'
                        />

                        <Modal.Content>
                            <p>
                                ¿Está seguro que desea archivar el registro?
                            </p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                basic
                                inverted
                                color='red'
                                onClick={this.handleClose}
                            >
                                <Icon name='remove' /> No
                            </Button>
                            <Button
                                inverted
                                color='green'
                                data-name='modal-archive'
                                onClick={this.handleClick}
                            >
                                <Icon name='checkmark' /> Sí
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Form>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth
    };
};

export default connect(mapStateToProps)(EmployeeViewer);

