import Cookies from 'js-cookie';
import React,{Component,Fragment} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Checkbox,
    Container,
    Divider,
    Dropdown,
    Header,
    Icon,
    Input,
    Form,
    Menu,
    Modal,
    Segment
} from 'semantic-ui-react';

import Countries from '../translate/countries.es';
import {
    Photo
} from '../components';
import {
    COUNTRIES_WITHOUT_FLAG
} from '../config';
import {
    apiFetch,
    humanStatus,
    message
} from '../utils';

class EnterpriseViewer extends Component{
    constructor(props){
        super(props);

        this.state={
            mode:this.props.mode,
            id:this.props.match.params.enterprise,
            data:{
                name:'',
                country:'',
                status:'',
                permissions:{}
            },
            permissions:{},
            modal:false,
            errors:[]
        };

        this.handlePhoto=this.handlePhoto.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleClick=this.handleClick.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    componentDidMount(){
        const {
            id,
            permissions
        }=this.state;

        apiFetch({
            url:'/api/enterprises/permissions',
            method:'GET',
            success:(data)=>{
                this.setState({
                    permissions:data
                });

                if(!id){
                    this.setState({
                        data:{
                            permissions:Object.keys(permissions)
                            .map((i)=>{
                                return {
                                    key:i,
                                    value:permissions[i].value
                                };
                            })
                            .reduce((sum,i)=>{
                                sum[i.key]=i.value;

                                return sum;
                            },{})
                        }
                    });
                }
            },
            error:(error)=>{
                message(false,error.body.message);
            }
        });

        if(id){
            apiFetch({
                url:'/api/enterprises/'+id,
                method:'GET',
                success:(data)=>{
                    this.setState({
                        data:data
                    });
                },
                error:(error)=>{
                    message(false,error.body.message);
                }
            });
        }
    }

    getCountries(){
        return Countries
        .map((country)=>{
            return {
                key:country.code,
                value:country.code,
                flag:COUNTRIES_WITHOUT_FLAG.includes(country.code)?
                    null:country.code,
                text:country.label
            };
        });
    }

    getCountry(code){
        let item=Countries.find((i)=>{
            return i.code===code;
        });

        if(item){
            return item.label;
        }else{
            return '---';
        }
    }

    getPermission(permission){
        const {
            permissions
        }=this.state.data;

        if(permissions){
            if(permission in permissions){
                return permissions[permission];
            }else{
                return false;
            }
        }else{
            return false;
        }
    }

    handlePhoto(files){
        const {
            id
        }=this.state;

        let formData=new FormData(),
            file=files[0].src.file,
            status;

        formData.append(
            'photo',
            file,
            file.name
        );

        fetch('/api/enterprises/'+id+'/photo',{
            method:'POST',
            credentials:'same-origin',
            headers:{
                'Accept':'application/json',
                'CSRF-Token':Cookies.get('firmanza.admin.csrf')
            },
            body:formData
        })
        .then((response)=>{
            status=response.status;

            return response.json();
        })
        .then((body)=>{
            if(status===200||status===201){
                this.componentDidMount();

                message(true,body.message);
            }else{
                message(false,body.message);
            }
        })
        .catch((e)=>{
            console.log(e);

            message(false,'No puede conectarse con el API');
        });
    }

    handleClose(){
        this.setState({
            modal:false
        });
    }

    handleChange(event,_data){
        const {
            data
        }=this.state;

        if(['name','country'].includes(_data.name)){
            this.setState({
                data:{
                    ...data,
                    [_data.name]:_data.value
                }
            });
        }else{
            this.setState({
                data:{
                    ...data,
                    permissions:{
                        ...data.permissions,
                        [_data.name]:_data.checked
                    }
                }
            });
        }
    }

    handleClick(event,_data){
        const {
            id
        }=this.state;

        event.stopPropagation();

        switch(_data['data-name']){
            case 'activate':
                apiFetch({
                    url:'/api/enterprises/'+id+'/activate',
                    method:'POST',
                    success:(__data)=>{
                        this.props.history.push('/enterprises/'+id+'/view');
                        this.componentDidMount();

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'deactivate':
                apiFetch({
                    url:'/api/enterprises/'+id+'/deactivate',
                    method:'POST',
                    success:(__data)=>{
                        this.props.history.push('/enterprises/'+id+'/view');
                        this.componentDidMount();

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'archive':
                this.setState({
                    modal:true
                });

                break;
            case 'modal-archive':
                apiFetch({
                    url:'/api/enterprises/'+id+'/archive',
                    method:'POST',
                    success:(__data)=>{
                        this.setState({
                            modal:false
                        });

                        this.props.history.push('/enterprises');

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            default:
                break;
        }
    }

    handleSubmit(event){
        const {
            mode,
            id,
            data
        }=this.state;

        event.preventDefault();

        if(mode==='add'){
            apiFetch({
                url:'/api/enterprises',
                method:'POST',
                data:{
                    name:data.name,
                    country:data.country,
                    permissions:data.permissions
                },
                success:(_data)=>{
                    this.props.history.push('/enterprises');

                    message(_data.ok,_data.message);
                },
                error:(error)=>{
                    if(error.status===400){
                        this.setState({
                            invalid_email:true,
                            invalid_email_text:error.body.message
                        });
                    }else{
                        message(false,error.body.message);
                    }
                }
            });
        }

        if(mode==='edit'){
            apiFetch({
                url:'/api/enterprises/'+id,
                method:'PUT',
                data:{
                    name:data.name,
                    country:data.country,
                    permissions:data.permissions
                },
                success:(_data)=>{
                    this.props.history.push('/enterprises/'+id+'/view');
                    this.componentDidMount();

                    message(_data.ok,_data.message);
                },
                error:(error)=>{
                    message(false,error.body.message);
                }
            });
        }
    }

    render(){
        const {
            mode,
            id,
            data,
            permissions,
            modal,
            errors
        }=this.state;

        if(
            !this.props.auth.logged||
            this.props.auth.role!=='employee'
        ){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container fluid>
                <Header as='h1'>Empresas</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>Inicio</a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <a href='/enterprises' className='section'>Empresas</a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    {mode==='edit'&&
                        <Fragment>
                            <a
                                href={'/enterprises/'+id+'/view'}
                                className='section'>
                                Ver registro
                            </a>
                            <i aria-hidden='true'
                                className='right angle icon divider'></i>
                        </Fragment>
                    }
                    {{
                        'add':<div className='active section'>
                            Nuevo registro</div>,
                        'view':<div className='active section'>
                            Ver registro</div>,
                        'edit':<div className='active section'>
                            Editar registro</div>
                    }[mode]}
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/enterprises'
                        name='Empresas activas'
                    />
                    <Menu.Item
                        as='a'
                        href='/enterprises/archive'
                        name='Empresas archivadas'
                    />
                    {{
                        'add':
                            <Menu.Item
                                as='a'
                                href='/enterprises/add'
                                name='Nuevo registro'
                                active
                            />,
                        'view':
                            <Menu.Item
                                as='a'
                                href={'/enterprises/'+id+'/view'}
                                name='Ver registro'
                                active
                            />,
                        'edit':
                            <Menu.Item
                                as='a'
                                href={'/enterprises/'+id+'/edit'}
                                name='Editar registro'
                                active
                            />
                    }[mode]}
                </Menu>

                {mode==='view'&&
                    <Fragment>
                        <Header as='h4' dividing>
                            Fotografía
                        </Header>
                        <Segment
                            basic
                            placeholder
                        >
                            <Photo
                                image={data.photo}
                                errors={errors}
                                onSuccess={this.handlePhoto}
                                setState={this.setState}
                            />
                        </Segment>
                    </Fragment>
                }

                <Form onSubmit={this.handleSubmit}>
                    <Header as='h4' dividing>
                        Datos de la empresa
                    </Header>

                    <Form.Group widths='equal'>
                        <Form.Field required={mode!=='view'}>
                            <label htmlFor='name'>
                                Nombres
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='name'
                                    size='small'
                                >
                                    {data.name}
                                </Segment>:
                                <Input
                                    name='name'
                                    type='text'
                                    icon='users'
                                    iconPosition='left'
                                    value={data.name||''}
                                    onChange={this.handleChange}
                                    required
                                />
                            }
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field required={mode!=='view'}>
                            <label htmlFor='country'>
                                País
                            </label>
                            {mode==='view'?
                                <Segment
                                    name='country'
                                    size='small'
                                >
                                    {this.getCountry(data.country)}
                                </Segment>:
                                <Dropdown
                                    name='country'
                                    placeholder='Seleccione un país'
                                    options={this.getCountries()}
                                    value={data.country}
                                    onChange={this.handleChange}
                                    fluid
                                    search
                                    selection
                                />
                            }
                        </Form.Field>
                        <Form.Field>
                        </Form.Field>
                    </Form.Group>

                    {mode==='view'&&
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <Form.Field>
                                    <label htmlFor='status'>
                                        Estado del registro
                                    </label>
                                    <Segment
                                        name='status'
                                        size='small'
                                    >
                                        {humanStatus(data.status||'')}
                                    </Segment>
                                </Form.Field>
                            </Form.Field>
                            <Form.Field>
                            </Form.Field>
                        </Form.Group>
                    }

                    <Header as='h4' dividing>
                        Funcionalidad disponible
                    </Header>

                    {Object.keys(permissions).map((permission)=>{
                        return (
                            <Fragment key={permission}>
                                <Form.Field>
                                    <Checkbox
                                        toggle
                                        id={permission}
                                        name={permission}
                                        label={permissions[permission]
                                        .description}
                                        onChange={this.handleChange}
                                        checked={this.getPermission(permission)}
                                        readOnly={mode==='view'}
                                    />
                                </Form.Field>
                                <Form.Field>
                                </Form.Field>
                            </Fragment>
                        );
                    })}

                    <Divider />
                    {{
                        'add':
                            <Button
                                icon
                                color='blue'
                                labelPosition='left'
                                type='submit'
                            >
                                <Icon name='save' />
                                Guardar
                            </Button>,
                        'view':
                            <Fragment>
                                {data.status==='active'?
                                    <Button
                                        icon
                                        color='red'
                                        labelPosition='left'
                                        data-name='deactivate'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='thumbs down' />
                                        Desactivar
                                    </Button>:
                                    <Button
                                        icon
                                        color='green'
                                        labelPosition='left'
                                        data-name='activate'
                                        onClick={this.handleClick}
                                    >
                                        <Icon name='thumbs up' />
                                        Activar
                                    </Button>
                                }

                                <Dropdown
                                    text='Opciones'
                                    icon='tasks'
                                    className='icon'
                                    labeled
                                    button
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            icon='save'
                                            content='Modificar'
                                            href={
                                                '/enterprises/'+id+'/edit'
                                            }
                                        />
                                        <Dropdown.Item
                                            icon='box'
                                            content='Archivar'
                                            data-name='archive'
                                            onClick={this.handleClick}
                                        />
                                        <Dropdown.Item
                                            icon='user'
                                            content='Lista de cuentas'
                                            href={
                                                '/enterprises/'+id+
                                                '/customers'
                                            }
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Fragment>,
                        'edit':
                            <Button
                                icon
                                color='blue'
                                labelPosition='left'
                                type='submit'
                            >
                                <Icon name='save' />
                                Guardar
                            </Button>
                    }[mode]}

                    <Modal
                        basic
                        size='small'
                        open={modal}
                        onClose={this.handleClose}
                    >
                        <Header
                            icon='archive'
                            content='Confirmación de archivo'
                        />

                        <Modal.Content>
                            <p>
                                ¿Está seguro que desea archivar el registro?
                            </p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                basic
                                inverted
                                color='red'
                                onClick={this.handleClose}
                            >
                                <Icon name='remove' /> No
                            </Button>
                            <Button
                                inverted
                                color='green'
                                data-name='modal-archive'
                                onClick={this.handleClick}
                            >
                                <Icon name='checkmark' /> Sí
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Form>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth
    };
};

export default connect(mapStateToProps)(EnterpriseViewer);

