import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import {Provider} from 'react-redux';
import {SemanticToastContainer} from 'react-semantic-toasts';
import {createStore} from 'redux';
import {Sidebar} from 'semantic-ui-react';

import {
    Home,
    Menubar,
    Offcanvas
} from './components';
import {
    ContactArchive,
    ContactList,
    ContactViewer,
    CustomerArchive,
    CustomerList,
    CustomerViewer,
    EmployeeArchive,
    EmployeeList,
    EmployeeViewer,
    EnterpriseArchive,
    EnterpriseCustomerArchive,
    EnterpriseCustomerList,
    EnterpriseCustomerViewer,
    EnterpriseList,
    EnterpriseViewer,
    Forgot,
    LogList,
    Reset,
    SerialArchive,
    SerialList,
    SerialViewer,
    Settings,
    Signin
} from './containers';

import allReducers from './reducers';
import * as serviceWorker from './serviceWorker';

import 'semantic-ui-css/components/reset.min.css';
import 'semantic-ui-css/components/site.min.css';
import 'semantic-ui-css/components/container.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/message.min.css';
import 'semantic-ui-css/components/header.min.css';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import './index.css';

const store=createStore(
    allReducers,
    window.__REDUX_DEVTOOLS_EXTENSION__&&
    window.__REDUX_DEVTOOLS_EXTENSION__()
);

document.getElementById('root')&&
    ReactDOM.render(
        <Provider store={store}>
            <Router>
                <Menubar />
                <Sidebar.Pushable>
                    <Offcanvas />
                    <Sidebar.Pusher>
                        <Switch>
                            <Route exact
                                path='/'
                                component={Home}
                            />
                            <Route exact
                                path='/signin'
                                component={Signin}
                            />

                            <Route exact
                                path='/forgot'
                                component={Forgot}
                            />
                            <Route exact
                                path='/reset/:key'
                                component={Reset}
                            />

                            <Route exact
                                path='/staff'
                                component={EmployeeList}
                            />
                            <Route exact
                                path='/staff/archive'
                                component={EmployeeArchive}
                            />
                            <Route exact
                                path='/staff/add'
                                render={(props)=>
                                    <EmployeeViewer
                                        mode='add'
                                        {...props}
                                    />
                                }
                            />
                            <Route
                                path='/staff/:employee/view'
                                render={(props)=>
                                    <EmployeeViewer
                                        mode='view'
                                        {...props}
                                    />
                                }
                            />
                            <Route
                                path='/staff/:employee/edit'
                                render={(props)=>
                                    <EmployeeViewer
                                        mode='edit'
                                        {...props}
                                    />
                                }
                            />

                            <Route exact
                                path='/serials'
                                component={SerialList}
                            />
                            <Route exact
                                path='/serials/archive'
                                component={SerialArchive}
                            />
                            <Route
                                path='/serials/:serial/view'
                                render={(props)=>
                                    <SerialViewer
                                        mode='view'
                                        {...props}
                                    />
                                }
                            />
                            <Route
                                path='/serials/:serial/edit'
                                render={(props)=>
                                    <SerialViewer
                                        mode='edit'
                                        {...props}
                                    />
                                }
                            />

                            <Route exact
                                path='/logs'
                                component={LogList}
                            />

                            <Route exact
                                path='/enterprises'
                                component={EnterpriseList}
                            />
                            <Route exact
                                path='/enterprises/archive'
                                component={EnterpriseArchive}
                            />
                            <Route exact
                                path='/enterprises/add'
                                render={(props)=>
                                    <EnterpriseViewer
                                        mode='add'
                                        {...props}
                                    />
                                }
                            />
                            <Route
                                path='/enterprises/:enterprise/view'
                                render={(props)=>
                                    <EnterpriseViewer
                                        mode='view'
                                        {...props}
                                    />
                                }
                            />
                            <Route
                                path='/enterprises/:enterprise/edit'
                                render={(props)=>
                                    <EnterpriseViewer
                                        mode='edit'
                                        {...props}
                                    />
                                }
                            />
                            <Route exact
                                path='/enterprises/:enterprise/customers'
                                component={EnterpriseCustomerList}
                            />
                            <Route exact
                                path={'/enterprises/:enterprise'+
                                    '/customers/archive'}
                                component={EnterpriseCustomerArchive}
                            />
                            <Route exact
                                path='/enterprises/:enterprise/customers/add'
                                render={(props)=>
                                    <EnterpriseCustomerViewer
                                        mode='add'
                                        {...props}
                                    />
                                }
                            />
                            <Route
                                path={'/enterprises/:enterprise'+
                                    '/customers/:customer/view'}
                                render={(props)=>
                                    <EnterpriseCustomerViewer
                                        mode='view'
                                        {...props}
                                    />
                                }
                            />
                            <Route
                                path={'/enterprises/:enterprise'+
                                    '/customers/:customer/edit'}
                                render={(props)=>
                                    <EnterpriseCustomerViewer
                                        mode='edit'
                                        {...props}
                                    />
                                }
                            />
                            <Route exact
                                path='/customers'
                                component={CustomerList}
                            />
                            <Route exact
                                path='/customers/archive'
                                component={CustomerArchive}
                            />
                            <Route exact
                                path='/customers/add'
                                render={(props)=>
                                    <CustomerViewer
                                        mode='add'
                                        {...props}
                                    />
                                }
                            />
                            <Route
                                path='/customers/:customer/view'
                                render={(props)=>
                                    <CustomerViewer
                                        mode='view'
                                        {...props}
                                    />
                                }
                            />
                            <Route
                                path='/customers/:customer/edit'
                                render={(props)=>
                                    <CustomerViewer
                                        mode='edit'
                                        {...props}
                                    />
                                }
                            />

                            <Route exact
                                path='/contacts'
                                component={ContactList}
                            />
                            <Route exact
                                path='/contacts/archive'
                                component={ContactArchive}
                            />
                            <Route
                                path='/contacts/:contact/view'
                                render={(props)=>
                                    <ContactViewer
                                        mode='view'
                                        {...props}
                                    />
                                }
                            />

                            <Route exact
                                path='/settings'
                                component={Settings}
                            />
                        </Switch>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
                <SemanticToastContainer
                    position='bottom-center'
                    className='snackbar'
                />
            </Router>
        </Provider>,
        document.getElementById('root')
    );

serviceWorker.unregister();

