import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Container,
    List
} from 'semantic-ui-react';

export default ()=>{
    const auth=useSelector(state=>state.auth);

    if(!auth.logged){
        return (
            <Redirect to='/signin' />
        );
    }else{
        return (
            <Container>
                <List
                    divided
                    relaxed
                    verticalAlign='middle'
                >
                    {auth.role==='admin'&&
                        <List.Item>
                            <List.Icon
                                name='users'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/staff'
                                >
                                    Lista del personal
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    }
                    {auth.role==='admin'&&
                        <List.Item>
                            <List.Icon
                                name='users'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/staff/archive'
                                >
                                    Personal archivado
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    }
                    {auth.role==='admin'&&
                        <List.Item>
                            <List.Icon
                                name='users'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/staff/add'
                                >
                                    Registrar nuevo personal
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    }

                    {auth.role==='admin'&&
                        <List.Item>
                            <List.Icon
                                name='key'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/serials'
                                >
                                    Lista del seriales
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    }
                    {auth.role==='admin'&&
                        <List.Item>
                            <List.Icon
                                name='key'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/serials/archive'
                                >
                                    Seriales revocados
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    }

                    {auth.role==='admin'&&
                        <List.Item>
                            <List.Icon
                                name='newspaper'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/logs'
                                >
                                    Bitácora del sistema
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    }

                    {auth.role==='employee'&&
                        <List.Item>
                            <List.Icon
                                name='building'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/enterprises'
                                >
                                    Lista de empresas
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    }
                    {auth.role==='employee'&&
                        <List.Item>
                            <List.Icon
                                name='building'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/enterprises/archive'
                                >
                                    Empresas archivadas
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    }
                    {auth.role==='employee'&&
                        <List.Item>
                            <List.Icon
                                name='building'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/enterprises/add'
                                >
                                    Registrar nueva empresa
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    }

                    {auth.role==='employee'&&
                        <List.Item>
                            <List.Icon
                                name='user'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/customers'
                                >
                                    Lista de clientes
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    }
                    {auth.role==='employee'&&
                        <List.Item>
                            <List.Icon
                                name='user'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/customers/archive'
                                >
                                    Clientes archivados
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    }
                    {auth.role==='employee'&&
                        <List.Item>
                            <List.Icon
                                name='user'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/customers/add'
                                >
                                    Registrar nuevo cliente
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    }

                    {auth.role==='employee'&&
                        <List.Item>
                            <List.Icon
                                name='mail'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/contacts'
                                >
                                    Lista de mensajes
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    }
                    {auth.role==='employee'&&
                        <List.Item>
                            <List.Icon
                                name='mail'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/contacts/archive'
                                >
                                    Mensajes archivados
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    }

                    {auth.role==='employee'&&
                        <List.Item>
                            <List.Icon
                                name='cog'
                                size='big'
                            />
                            <List.Content>
                                <List.Header
                                    as='a'
                                    href='/settings'
                                >
                                    Configuración de cuenta
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    }
                </List>
            </Container>
        );
    }
};

