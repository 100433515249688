import {createMedia} from '@artsy/fresnel';
import React,{Fragment} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {withRouter} from 'react-router';
import {
    Icon,
    Menu
} from 'semantic-ui-react';

import {
    RESPONSIVE_TRIGGER
} from '../config';
import {
    AUTH_OUT,
    OFFCANVAS_HIDDEN,
    OFFCANVAS_VISIBLE
} from '../constants';
import {
    apiFetch,
    message
} from '../utils';

const {
        MediaContextProvider,
        Media
    }=createMedia({
        breakpoints:{
            sm:0,
            md:RESPONSIVE_TRIGGER
        }
    }),
    hide=()=>{
        return {
            type:OFFCANVAS_HIDDEN
        };
    },
    show=()=>{
        return {
            type:OFFCANVAS_VISIBLE
        };
    },
    logout=()=>{
        return {
            type:AUTH_OUT
        };
    };

export default withRouter((props)=>{
    const offcanvas=useSelector(state=>state.offcanvas),
        auth=useSelector(state=>state.auth),
        dispatch=useDispatch(),
        handleClick=(event)=>{
            event.preventDefault();

            apiFetch({
                url:'/api/_session',
                method:'DELETE',
                data:{},
                success:(data)=>{
                    dispatch(logout());
                    props.history.push('/signin');

                    message(data.ok,data.message);
                },
                error:(error)=>{
                    message(false,error.body.message);
                }
            });
        };

    return (
        <MediaContextProvider>
            <Menu
                id='menubar'
                inverted
            >
                <Media at='sm'>
                    <Menu.Item
                        id='bars'
                        as='a'
                        onClick={()=>dispatch(offcanvas?hide():show())}
                    >
                        <Icon name='bars' />
                    </Menu.Item>
                </Media>
                <Media greaterThanOrEqual='md'>
                    <Menu.Item
                        as='a'
                        href='/'
                    >
                        Inicio
                    </Menu.Item>
                </Media>

                {auth.role==='admin'&&
                    <Fragment>
                        <Media greaterThanOrEqual='md'>
                            <Menu.Item
                                as='a'
                                href='/staff'
                            >
                                Personal
                            </Menu.Item>
                        </Media>
                        <Media greaterThanOrEqual='md'>
                            <Menu.Item
                                as='a'
                                href='/serials'
                            >
                                Seriales
                            </Menu.Item>
                        </Media>
                        <Media greaterThanOrEqual='md'>
                            <Menu.Item
                                as='a'
                                href='/logs'
                            >
                                Bitácora
                            </Menu.Item>
                        </Media>
                    </Fragment>
                }

                {auth.role==='employee'&&
                    <Fragment>
                        <Media greaterThanOrEqual='md'>
                            <Menu.Item
                                as='a'
                                href='/enterprises'
                            >
                                Empresas
                            </Menu.Item>
                        </Media>
                        <Media greaterThanOrEqual='md'>
                            <Menu.Item
                                as='a'
                                href='/customers'
                            >
                                Clientes
                            </Menu.Item>
                        </Media>
                        <Media greaterThanOrEqual='md'>
                            <Menu.Item
                                as='a'
                                href='/contacts'
                            >
                                Mensajes
                            </Menu.Item>
                        </Media>
                        <Media greaterThanOrEqual='md'>
                            <Menu.Item
                                as='a'
                                href='/settings'
                            >
                                Configuración
                            </Menu.Item>
                        </Media>
                    </Fragment>
                }

                {auth.logged?
                    <Menu.Item
                        as='a'
                        className='right item'
                        onClick={handleClick}
                    >
                        Salir
                    </Menu.Item>:
                    <Menu.Item
                        as='a'
                        href='/signin'
                        className='right item'
                    >
                        Ingresar
                    </Menu.Item>
                }
            </Menu>
        </MediaContextProvider>
    );
});

