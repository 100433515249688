import Cookies from 'js-cookie';
import {toast} from 'react-semantic-toasts';

function apiFetch({
    url,
    method,
    data,
    success,
    error=()=>{}
}){
    if(method!=='GET'){
        if(!data){
            data={};
        }

        data._csrf=Cookies.get('firmanza.admin.csrf');
    }

    let status;

    return fetch(url,{
        method:method,
        credentials:'same-origin',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(data)
    })
    .then((response)=>{
        status=response.status;

        return response.json();
    })
    .then((body)=>{
        if(status===200||status===201){
            return success(body);
        }else{
            return error({
                status:status,
                body:body
            });
        }
    })
    .catch((e)=>{
        console.log(e);

        return error({
            status:0,
            body:{
                ok:false,
                message:'No puede conectarse con el API'
            }
        });
    });
}

function humanStatus(status){
    switch(status){
        case 'confirm':
            return 'Pendiente';
        case 'forgot':
            return 'Olvidado';
        case 'assigned':
            return 'Procesado';
        case 'unassigned':
            return 'Sin procesar';
        case 'acquired':
            return 'Solicitante';
        case 'active':
            return 'Activo';
        case 'inactive':
            return 'Inactivo';
        case 'archived':
            return 'Archivado';
        default:
            return status;
    }
}

function message(ok,message){
    toast({
        title:ok?'Petición correcta':'Petición fallida',
        description:message,
        type:ok?'success':'error',
        icon:ok?'check':'times',
        animation:ok?'fade down':'fade up',
        time:10000
    });
}

export {
    apiFetch,
    humanStatus,
    message
};

