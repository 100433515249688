import React,{Fragment} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {withRouter} from 'react-router';
import {
    Menu,
    Sidebar
} from 'semantic-ui-react';

import {
    AUTH_OUT
} from '../constants';
import {
    apiFetch,
    message
} from '../utils';

const logout=()=>{
    return {
        type:AUTH_OUT
    };
};

export default withRouter(function(props){
    const offcanvas=useSelector(state=>state.offcanvas),
        auth=useSelector(state=>state.auth),
        dispatch=useDispatch();

    function handleClick(event){
        event.preventDefault();

        apiFetch({
            url:'/api/_session',
            method:'DELETE',
            data:{},
            success:(data)=>{
                dispatch(logout());
                props.history.push('/signin');

                message(data.ok,data.message);
            },
            error:(error)=>{
                message(false,error.body.message);
            }
        });
    }

    return (
        <Sidebar
            id='sidebar'
            as={Menu}
            icon='labeled'
            inverted
            animation='slide along'
            vertical
            visible={offcanvas}
            width='thin'
        >
            <Menu.Item
                as='a'
                href='/'
            >
                Inicio
            </Menu.Item>

            {auth.role==='admin'&&
                <Fragment>
                    <Menu.Item
                        as='a'
                        href='/staff'
                    >
                        Personal
                    </Menu.Item>
                    <Menu.Item
                        as='a'
                        href='/serials'
                    >
                        Seriales
                    </Menu.Item>
                    <Menu.Item
                        as='a'
                        href='/logs'
                    >
                        Bitácora
                    </Menu.Item>
                </Fragment>
            }

            {auth.role==='employee'&&
                <Fragment>
                    <Menu.Item
                        as='a'
                        href='/enterprises'
                    >
                        Empresas
                    </Menu.Item>
                    <Menu.Item
                        as='a'
                        href='/customers'
                    >
                        Clientes
                    </Menu.Item>
                    <Menu.Item
                        as='a'
                        href='/contacts'
                    >
                        Mensajes
                    </Menu.Item>
                    <Menu.Item
                        as='a'
                        href='/settings'
                    >
                        Configuración
                    </Menu.Item>
                </Fragment>
            }

            {auth.logged?
                <Menu.Item
                    as='a'
                    onClick={handleClick}
                >
                    Salir
                </Menu.Item>:
                <Menu.Item
                    as='a'
                    href='/signin'
                >
                    Ingresar
                </Menu.Item>
            }
        </Sidebar>
    );
});

