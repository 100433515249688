import Cookies from 'js-cookie';
import React,{Component,Fragment} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Divider,
    Header,
    Icon,
    Input,
    Form,
    Label,
    Segment
} from 'semantic-ui-react';

import {
    Photo
} from '../components';
import {
    AUTH_IN
} from '../constants';
import {
    apiFetch,
    message
} from '../utils';

class Settings extends Component{
    constructor(props){
        super(props);

        this.state={
            data:{
                name:'',
                surname:'',
                id:'',
                email:'',
                password:'',
                password1:'',
                password2:''
            },
            invalid_password:false,
            errors:[]
        };

        this.handlePhoto=this.handlePhoto.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }

    componentDidMount(){
        apiFetch({
            url:'/api/settings',
            method:'GET',
            success:(data)=>{
                this.setState({
                    data:data
                });
            },
            error:(error)=>{
                message(false,error.body.message);
            }
        });
    }

    handleChange(event,_data){
        const {
            data
        }=this.state;

        this.setState({
            data:{
                ...data,
                [_data.name]:_data.value
            }
        });

        if(data.name==='password1'){
            this.setState({
                invalid_password:(data.password2!==_data.value)
            });
        }

        if(data.name==='password2'){
            this.setState({
                invalid_password:(data.password1!==_data.value)
            });
        }
    }

    handleSubmit(event){
        const {
            data
        }=this.state;

        event.preventDefault();

        apiFetch({
            url:'/api/settings/password',
            method:'POST',
            data:{
                password1:data.password,
                password2:data.password1
            },
            success:(__data)=>{
                this.props.history.push('/');

                message(__data.ok,__data.message);
            },
            error:(error)=>{
                message(false,error.body.message);
            }
        });
    }

    handlePhoto(files){
        let formData=new FormData(),
            file=files[0].src.file,
            status;

        formData.append(
            'photo',
            file,
            file.name
        );

        fetch('/api/settings/photo',{
            method:'POST',
            credentials:'same-origin',
            headers:{
                'Accept':'application/json',
                'CSRF-Token':Cookies.get('firmanza.admin.csrf')
            },
            body:formData
        })
        .then((response)=>{
            status=response.status;

            return response.json();
        })
        .then((body)=>{
            if(status===200||status===201){
                message(true,body.message);

                this.componentDidMount();
            }else{
                message(false,body.message);
            }
        })
        .catch((e)=>{
            console.log(e);

            message(false,'No puede conectarse con el API');
        });
    }

    render(){
        const {
            data,
            invalid_password,
            errors
        }=this.state;

        if(
            !this.props.auth.logged||
            this.props.auth.role!=='employee'
        ){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container>
                <Header as='h1'>Configuración</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>Inicio</a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <div className='active section'>Configuración</div>
                </div>
                <br />

                <Fragment>
                    <Header as='h4' dividing>
                        Fotografía
                    </Header>
                    <Segment
                        basic
                        placeholder
                    >
                        <Photo
                            image={data.photo}
                            errors={errors}
                            onSuccess={this.handlePhoto}
                            setState={this.setState}
                        />
                    </Segment>
                </Fragment>

                <Form onSubmit={this.handleSubmit}>
                    <Header as='h4' dividing>
                        Datos personales
                    </Header>

                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label htmlFor='name'>Nombres</label>
                            <Segment
                                name='name'
                                size='small'
                            >
                                {data.name}
                            </Segment>
                        </Form.Field>
                        <Form.Field>
                            <label htmlFor='surname'>Apellidos</label>
                            <Segment
                                name='surname'
                                size='small'
                            >
                                {data.surname}
                            </Segment>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label htmlFor='id'>
                                Cédula de identidad personal o pasaporte
                            </label>
                            <Segment
                                name='id'
                                size='small'
                            >
                                {data.id}
                            </Segment>
                        </Form.Field>
                        <Form.Field>
                        </Form.Field>
                    </Form.Group>

                    <Header as='h4' dividing>
                        Datos de acceso
                    </Header>

                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label htmlFor='email'>Correo electrónico</label>
                            <Segment
                                name='email'
                                size='small'
                            >
                                {data.email}
                            </Segment>
                        </Form.Field>
                        <Form.Field>
                        </Form.Field>
                    </Form.Group>

                    <Header as='h4' dividing>
                        Cambiar contraseña
                    </Header>

                    <Form.Group widths='equal'>
                        <Form.Field required>
                            <label htmlFor='password'>Contraseña actual</label>
                            <Input
                                name='password'
                                type='password'
                                value={data.password||''}
                                onChange={this.handleChange}
                                icon='key'
                                iconPosition='left'
                                required
                            />
                        </Form.Field>
                        <Form.Field>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field required>
                            <label htmlFor='password1'>Contraseña nueva</label>
                            <Input
                                name='password1'
                                type='password'
                                value={data.password1||''}
                                onChange={this.handleChange}
                                icon='key'
                                iconPosition='left'
                                required
                            />
                            {invalid_password&&
                                <Label basic color='red' pointing>
                                    Contraseñas diferentes
                                </Label>
                            }
                        </Form.Field>
                        <Form.Field required>
                            <label htmlFor='password2'>
                                Repetir contraseña nueva
                            </label>
                            <Input
                                name='password2'
                                type='password'
                                value={data.password2||''}
                                onChange={this.handleChange}
                                icon='key'
                                iconPosition='left'
                                required
                            />
                            {invalid_password&&
                                <Label basic color='red' pointing>
                                    Contraseñas diferentes
                                </Label>
                            }
                        </Form.Field>
                    </Form.Group>

                    <Divider />
                    <Button
                        icon
                        color='blue'
                        labelPosition='left'
                        type='submit'
                    >
                        <Icon name='save' />
                        Guardar
                    </Button>
                </Form>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth
    };
};

const mapDispatchToProps=(dispatch)=>{
    return {
        login:(role)=>{
            dispatch({
                type:AUTH_IN,
                role:role
            });
        }
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(Settings);

