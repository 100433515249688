import React,{Component,Fragment} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Divider,
    Dropdown,
    Header,
    Icon,
    Form,
    Menu,
    Modal,
    Segment
} from 'semantic-ui-react';

import {
    apiFetch,
    humanStatus,
    message
} from '../utils';

class ContactViewer extends Component{
    constructor(props){
        super(props);

        this.state={
            id:this.props.match.params.contact,
            data:{
                fullname:'',
                email:'',
                enterprise:'',
                phone:'',
                subject:'',
                status:''
            },
            modal:false
        };

        this.handleClose=this.handleClose.bind(this);
        this.handleClick=this.handleClick.bind(this);
    }

    componentDidMount(){
        const {
            id
        }=this.state;

        if(id){
            apiFetch({
                url:'/api/contacts/'+id,
                method:'GET',
                success:(data)=>{
                    this.setState({
                        data:data
                    });
                },
                error:(error)=>{
                    message(false,error.body.message);
                }
            });
        }
    }

    handleClose(){
        this.setState({
            modal:false
        });
    }

    handleClick(event,_data){
        const {
            id
        }=this.state;

        event.stopPropagation();

        switch(_data['data-name']){
            case 'assign':
                apiFetch({
                    url:'/api/contacts/'+id+'/assign',
                    method:'POST',
                    success:(__data)=>{
                        this.props.history.push('/contacts/'+id+'/view');
                        this.componentDidMount();

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'unassign':
                apiFetch({
                    url:'/api/contacts/'+id+'/unassign',
                    method:'POST',
                    success:(__data)=>{
                        this.props.history.push('/contacts/'+id+'/view');
                        this.componentDidMount();

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            case 'archive':
                this.setState({
                    modal:true
                });

                break;
            case 'modal-archive':
                apiFetch({
                    url:'/api/contacts/'+id+'/archive',
                    method:'POST',
                    success:(__data)=>{
                        this.setState({
                            modal:false
                        });

                        this.props.history.push('/contacts');

                        message(__data.ok,__data.message);
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            default:
                break;
        }
    }

    render(){
        const {
            id,
            data,
            modal
        }=this.state;

        if(!this.props.auth.logged||this.props.auth.role!=='employee'){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container>
                <Header as='h1'>Mensajes</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>Inicio</a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <a href='/contacts' className='section'>
                        Mensajes
                    </a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <div className='active section'>
                        Ver mensaje</div>
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/contacts'
                        name='Mensajes activos'
                    />
                    <Menu.Item
                        as='a'
                        href='/contacts/archive'
                        name='Mensajes archivados'
                    />
                    <Menu.Item
                        as='a'
                        href={'/contacts/'+id+'/view'}
                        name='Ver rmensaje'
                        active
                    />
                </Menu>

                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label htmlFor='fullname'>Nombre completo</label>
                            <Segment
                                name='fullname'
                                size='small'
                            >
                                &nbsp;{data.fullname}
                            </Segment>
                        </Form.Field>
                        <Form.Field>
                            <label htmlFor='email'>Correo electrónico</label>
                            <Segment
                                name='email'
                                size='small'
                            >
                                &nbsp;{data.email}
                            </Segment>
                        </Form.Field>
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label htmlFor='enterprise'>Empresa</label>
                            <Segment
                                name='enterprise'
                                size='small'
                            >
                                &nbsp;{data.enterprise}
                            </Segment>
                        </Form.Field>
                        <Form.Field>
                            <label htmlFor='phone'>Telefóno</label>
                            <Segment
                                name='phone'
                                size='small'
                            >
                                &nbsp;{data.phone}
                            </Segment>
                        </Form.Field>
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label htmlFor='subject'>Mensaje</label>
                            <Segment
                                name='subject'
                                size='small'
                            >
                                {data.subject}
                            </Segment>
                        </Form.Field>
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Field>
                                <label htmlFor='status'>
                                    Estado del registro
                                </label>
                                <Segment
                                    name='status'
                                    size='small'
                                >
                                    {humanStatus(data.status||'')}
                                </Segment>
                            </Form.Field>
                        </Form.Field>
                        <Form.Field>
                        </Form.Field>
                    </Form.Group>

                    <Divider />

                    <Fragment>
                        {data.status==='assigned'?
                            <Button
                                icon
                                color='red'
                                labelPosition='left'
                                data-name='unassign'
                                onClick={this.handleClick}
                            >
                                <Icon name='thumbs down' />
                                Marcar sin procesar
                            </Button>:
                            <Button
                                icon
                                color='green'
                                labelPosition='left'
                                data-name='assign'
                                onClick={this.handleClick}
                            >
                                <Icon name='thumbs up' />
                                Marcar como procesado
                            </Button>
                        }

                        <Dropdown
                            text='Opciones'
                            icon='tasks'
                            className='icon'
                            labeled
                            button
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    icon='box'
                                    content='Archivar'
                                    data-name='archive'
                                    onClick={this.handleClick}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Fragment>

                    <Modal
                        basic
                        size='small'
                        open={modal}
                        onClose={this.handleClose}
                    >
                        <Header
                            icon='archive'
                            content='Confirmación de archivo'
                        />

                        <Modal.Content>
                            <p>
                                ¿Está seguro de que desea archivar el
                                registro?
                            </p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                basic
                                inverted
                                color='red'
                                onClick={this.handleClose}
                            >
                                <Icon name='remove' /> No
                            </Button>
                            <Button
                                inverted
                                color='green'
                                data-name='modal-archive'
                                onClick={this.handleClick}
                            >
                                <Icon name='checkmark' /> Sí
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Form>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth
    };
};

export default connect(mapStateToProps)(ContactViewer);

