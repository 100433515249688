import React,{Fragment} from 'react';
import {
    Icon,
    Pagination,
    Table
} from 'semantic-ui-react';

export default ({
    total,
    limit,
    page,
    colspan,
    handlePagination
})=>(
    <Table.Footer>
        <Table.Row>
            <Table.Cell colSpan={colspan}>
                {total>limit&&
                    <Fragment>
                        <Pagination
                            floated='right'
                            activePage={page}
                            totalPages={Math.ceil(total/limit)}
                            onPageChange={handlePagination}
                            size='mini'
                            ellipsisItem={{
                                content:<Icon name='ellipsis horizontal' />,
                                icon:true
                            }}
                            firstItem={{
                                content:<Icon name='angle double left' />,
                                icon:true
                            }}
                            lastItem={{
                                content:<Icon name='angle double right' />,
                                icon:true
                            }}
                            prevItem={{
                                content:<Icon name='angle left' />,
                                icon:true
                            }}
                            nextItem={{
                                content:<Icon name='angle right' />,
                                icon:true
                            }}
                        />
                        <p className='pagination-desc'>
                            {((page-1)*limit)+1}
                            &nbsp;-&nbsp;
                            {Math.min(total,(page)*limit)}
                            &nbsp;de&nbsp;
                            {total}
                        </p>
                    </Fragment>
                }
            </Table.Cell>
        </Table.Row>
    </Table.Footer>
);

