import {combineReducers} from 'redux';

import auth from './auth';
import offcanvas from './offcanvas';

export default combineReducers({
    auth:auth,
    offcanvas:offcanvas
});

