import React,{Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
    Button,
    Container,
    Flag,
    Grid,
    Header,
    Icon,
    Image,
    Input,
    Menu,
    Message,
    Modal,
    Table
} from 'semantic-ui-react';

import {
    Pagination
} from '../components';
import {
    PAGINATION_LIMIT
} from '../config';
import {
    apiFetch,
    humanStatus,
    message
} from '../utils';

class CustomerList extends Component{
    constructor(props){
        super(props);

        this.state={
            error:null,
            isLoaded:false,
            total:0,
            items:[],
            search:'',
            page:1,
            modal:false,
            archive:null
        };

        this.handleSearch=this.handleSearch.bind(this);
        this.handlePagination=this.handlePagination.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleClick=this.handleClick.bind(this);
    }

    loadData(search,page){
        apiFetch({
            url:'/api/customers?'+[
                'search='+search,
                'offset='+((page-1)*PAGINATION_LIMIT),
                'limit='+PAGINATION_LIMIT
            ].join('&'),
            method:'GET',
            success:(data)=>{
                this.setState({
                    isLoaded:true,
                    total:data.total,
                    items:data.list,
                    search:search,
                    page:page
                });
            },
            error:(error)=>{
                this.setState({
                    isLoaded:true,
                    error:error
                });
            }
        });
    }

    componentDidMount(){
        this.loadData('',1);
    }

    handleSearch(event,data){
        this.loadData(data.value,1);
    }

    handlePagination(event,data){
        const {
            search
        }=this.state;

        this.loadData(search,data.activePage);
    }

    handleClose(){
        this.setState({
            modal:false
        });
    }

    handleClick(event,data){
        const {
            items,
            archive
        }=this.state;

        event.stopPropagation();

        let index=+data['data-index'];

        switch(data['data-name']){
            case 'archive':
                this.setState({
                    modal:true,
                    archive:items[index]._id
                });

                break;
            case 'modal-archive':
                apiFetch({
                    url:'/api/customers/'+archive+'/archive',
                    method:'POST',
                    success:(data)=>{
                        this.setState({
                            modal:false,
                            archive:null
                        });

                        message(data.ok,data.message);

                        this.componentDidMount();
                    },
                    error:(error)=>{
                        message(false,error.body.message);
                    }
                });

                break;
            default:
                break;
        }
    }

    render(){
        const {
            error,
            isLoaded,
            total,
            items,
            page,
            modal
        }=this.state;

        if(
            !this.props.auth.logged||
            this.props.auth.role!=='employee'
        ){
            return (
                <Redirect to='/' />
            );
        }

        return (
            <Container fluid>
                <Header as='h1'>Clientes</Header>

                <div className='ui breadcrumb'>
                    <a href='/' className='section'>Inicio</a>
                    <i aria-hidden='true'
                        className='right angle icon divider'></i>
                    <div className='active section'>Clientes</div>
                </div>
                <br />

                <Menu tabular>
                    <Menu.Item
                        as='a'
                        href='/customers'
                        name='Clientes activos'
                        active
                    />
                    <Menu.Item
                        as='a'
                        href='/customers/archive'
                        name='Clientes archivados'
                    />
                    <Menu.Item
                        as='a'
                        href='/customers/add'
                        name='Nuevo registro'
                    />
                </Menu>

                <Grid>
                    <Grid.Column width={12}>
                        &nbsp;
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Input
                            className='search-input'
                            size='small'
                            icon={{
                                name:'search',
                                circular:true,
                                link:true
                            }}
                            placeholder='Buscar...'
                            onChange={this.handleSearch}
                        />
                    </Grid.Column>
                </Grid>
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Nombre completo
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width={2}>
                                Empresa
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='center' width={2}>
                                Estado
                            </Table.HeaderCell>
                            <Table.HeaderCell width={2}>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {error&&
                            <Table.Row>
                                <Table.Cell colSpan='4'>
                                    <Message negative>
                                        <Message.Header>
                                            No pudieron cargarse los datos
                                        </Message.Header>
                                        <p>{error.body.message}</p>
                                    </Message>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {!isLoaded&&
                            <Table.Row>
                                <Table.Cell colSpan='4'>
                                    <Message icon info>
                                        <Icon name='circle notched' loading />
                                        <Message.Content>
                                            <Message.Header>
                                                Espera un momento
                                            </Message.Header>
                                            <p>
                                                Cargando la información del
                                                servidor ...
                                            </p>
                                        </Message.Content>
                                    </Message>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {items.map((item,i)=>(
                            <Table.Row
                                key={item._id}
                                className={item.status==='inactive'?'error':''}
                            >
                                <Table.Cell>
                                    <Header as='h4' image>
                                        <Image
                                            circular
                                            size='medium'
                                            src={item.photo} />
                                        <Header.Content>
                                            {item.fullname}
                                            <Header.Subheader>
                                                <Flag name={item.country} />
                                                {item.email}
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {item.enterprise&&
                                        <Icon color='green' name='checkmark' />
                                    }
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {humanStatus(item.status)}
                                </Table.Cell>
                                <Table.Cell textAlign='right'>
                                    <Button.Group basic size='small'>
                                        <Button
                                            icon='eye'
                                            title='Ver registro'
                                            href={'/customers/'+
                                                item._id+'/view'}
                                        />
                                        <Button
                                            icon='save'
                                            title='Editar registro'
                                            href={'/customers/'+
                                                item._id+'/edit'}
                                        />
                                        <Button
                                            icon='box'
                                            title='Archivar registro'
                                            data-name='archive'
                                            data-index={i}
                                            onClick={this.handleClick}
                                        />
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>

                    <Pagination
                        total={total}
                        limit={PAGINATION_LIMIT}
                        page={page}
                        colspan={4}
                        handlePagination={this.handlePagination}
                    />
                </Table>

                <Modal
                    basic
                    size='small'
                    open={modal}
                    onClose={this.handleClose}
                >
                    <Header icon='archive' content='Confirmación de archivo' />
                    <Modal.Content>
                        <p>
                            ¿Está seguro que desea archivar el registro?
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            basic
                            inverted
                            color='red'
                            onClick={this.handleClose}
                        >
                            <Icon name='remove' /> No
                        </Button>
                        <Button
                            inverted
                            color='green'
                            data-name='modal-archive'
                            onClick={this.handleClick}
                        >
                            <Icon name='checkmark' /> Sí
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps=(state)=>{
    return {
        auth:state.auth
    };
};

export default connect(mapStateToProps)(CustomerList);

